/* eslint-disable camelcase */
// @flow

import { jitsiLocalStorage } from '@jitsi/js-utils';
import type { Dispatch } from 'redux';

import { addKnownDomains } from '../known-domains';
import { parseURIString } from '../util';

import {
    CONFIG_WILL_LOAD,
    LOAD_CONFIG_ERROR,
    SET_CONFIG,
    UPDATE_CONFIG,
    OVERWRITE_CONFIG
} from './actionTypes';
import { _CONFIG_STORE_PREFIX } from './constants';
import { setConfigFromURLParams } from './functions';

import getRoomName from './getRoomName';
import  logger  from './logger'; // for ProSpatium
import {redirectToStaticPage} from '../../app/actions';
import { isMobileBrowser } from '../environment/utils';
import { parseURLParams } from "../../base/util";
import { checkAgreementConfigValue, checkAgreementPlatformConfigValue } from '../../prejoin/functions';

declare var interfaceConfig: Object;

/**
 * Updates the config with new options.
 *
 * @param {Object} config - The new options (to add).
 * @returns {Function}
 */
export function updateConfig(config: Object) {
    return {
        type: UPDATE_CONFIG,
        config
    };
}

/**
 * Signals that the configuration (commonly known in Jitsi Meet as config.js)
 * for a specific locationURL will be loaded now.
 *
 * @param {URL} locationURL - The URL of the location which necessitated the
 * loading of a configuration.
 * @param {string} room - The name of the room (conference) for which we're loading the config for.
 * @returns {{
 *     type: CONFIG_WILL_LOAD,
 *     locationURL: URL,
 *     room: string
 * }}
 */
export function configWillLoad(locationURL: URL, room: string) {
    return {
        type: CONFIG_WILL_LOAD,
        locationURL,
        room
    };
}

/**
 * Signals that a configuration (commonly known in Jitsi Meet as config.js)
 * could not be loaded due to a specific error.
 *
 * @param {Error} error - The {@code Error} which prevented the successful
 * loading of a configuration.
 * @param {URL} locationURL - The URL of the location which necessitated the
 * loading of a configuration.
 * @returns {{
 *     type: LOAD_CONFIG_ERROR,
 *     error: Error,
 *     locationURL: URL
 * }}
 */
export function loadConfigError(error: Error, locationURL: URL) {
    return {
        type: LOAD_CONFIG_ERROR,
        error,
        locationURL
    };
}

/**
 * Overwrites some config values.
 *
 * @param {Object} config - The new options (to overwrite).
 * @returns {{
 *     type: OVERWRITE_CONFIG,
 *     config: Object
 * }}
 */
export function overwriteConfig(config: Object) {
    return {
        type: OVERWRITE_CONFIG,
        config
    };
}


function arrayRemove(arr, value){
    return arr.filter(function(ele){
        return ele !== value;
    })
}

function isShowPrejoinPage(scheduleConfing) {
    const interface_config = scheduleConfing.schedule_interface_config;
    const property_interface_config = scheduleConfing.property_interface_config;
    console.log('[PREJOIN] interface_config', interface_config);

    if (interface_config && interface_config.PS_LOBBY !== 0) {
        return true;
    }

    const urlParams = parseURLParams(
        window.location,
        false,
        'hash'
    );

    const agreement = urlParams["agreement"];
    if (agreement !== 'show') {
        return false;
    }

    //var showTermsAgreement =
    //typeof property_interface_config.PS_SHOW_TERMS_AGREEMENT === "undefined"
    //    ? 'skip'
    //    : property_interface_config.PS_SHOW_TERMS_AGREEMENT;
    var showTermsAgreement = checkAgreementConfigValue(property_interface_config.PS_SHOW_TERMS_AGREEMENT);

    //var showTermsAgreementPlatform =
    //typeof property_interface_config.PS_SHOW_TERMS_AGREEMENT_PLATFORM === "undefined"
        //? 'all'
        //: property_interface_config.PS_SHOW_TERMS_AGREEMENT_PLATFORM;
    var showTermsAgreementPlatform = checkAgreementPlatformConfigValue (property_interface_config.PS_SHOW_TERMS_AGREEMENT_PLATFORM);

    if (showTermsAgreement === 'show' || showTermsAgreement === 'custom') {
        if (showTermsAgreementPlatform === 'all') {
            return true;
        } else if (showTermsAgreementPlatform === 'mobile') {
            if (isMobileBrowser()) {
                return true;
            }
        } else if (showTermsAgreementPlatform === 'web') {
            if (!isMobileBrowser()) {
                return true;
            }
        } else {
            console.log('[showTermsAgreement]check platform.', showTermsAgreementPlatform);
            return false;
        }
    }
    return false;
}

async function getPropetyVirtaulBackground(domain: String, property: String) {
    try {
        const url = `${domain}/storage/virtual-background/${property}/`;
        const res = await fetch(url + 'list.json', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json'
            },
            cache: 'no-cache'
        });
        const json = await res.json();

        if (res.ok) {
            const imageList = json.images;
            const propertyImageList = [];
            for (let imgData of imageList) {
                //save image list at session storage.
                propertyImageList.push(
                    {
                        id: imgData.id,
                        src: url + imgData.fileName
                    }
                );
            }
            return propertyImageList;
        } else {
            return [];
        }
    } catch (err) {
        logger.info('Get Property Virbackground Image List Error', err);
        return [];
    }
}
/**
 * Sets the configuration represented by the feature base/config. The
 * configuration is defined and consumed by the library lib-jitsi-meet but some
 * of its properties are consumed by the application jitsi-meet as well.
 *
 * @param {Object} config - The configuration to be represented by the feature
 * base/config.
 * @returns {Function}
 */
export async function setConfig(config: Object = {}) {
    // ProSpatium Start
    if (navigator.product !== 'ReactNative') {
        console.log('[ProSpatium]');

        if(window.defaultLanguage !== undefined){
            config.defaultLanguage = window.defaultLanguage;
        }
    }

    try{
        const path = "static/roomSchedule.html";

        let roomName, url = "/api/v1/app/enterenceCheck/";
        if (navigator.product === 'ReactNative') {
            roomName = config.room;
            url = `https://${config.hosts.domain}${url}`;
        } else {
            roomName = getRoomName();
        }

        if (roomName) {
            const [ property_code, schedule_url_title ]
                = roomName.indexOf('_-_') > -1 ? roomName.split('_-_') : [ '', roomName ];
            const body = {
                property_code,
                schedule_url_title
            };
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            const json = await res.json();
            let isNG = false;

            if (res.ok) {
                const scheduleConfing = json.data[0];

                config.prospatium = scheduleConfing;
                if(scheduleConfing.property_interface_config.TOOLBAR_BUTTONS !== undefined){
                    config.toolbarButtons = scheduleConfing.property_interface_config.TOOLBAR_BUTTONS;
                }
                interfaceConfig.DEFAULT_LOGO_URL = scheduleConfing.property_ci;
                logger.info('[SCHEDULE_INFO] prospatium_info', json.data.length , scheduleConfing);
                if (json.data.length === 0) {
                    isNG = true;
                } else {
                    // Prejoin page
                    // eslint-disable-next-line max-depth, no-lonely-if
                    config.prospatium.property_virtual_background = await getPropetyVirtaulBackground(`https://${config.hosts.domain}`, property_code.toLowerCase());
                    if (isShowPrejoinPage(scheduleConfing)) {
                        console.log('[PREJOIN] enable Prejoin');
                        config.prejoinPageEnabled = true;
                    }

                    // preferredCodec
                    if (config.prospatium.property_interface_config.PREFERRED_CODEC !== undefined) {
                        console.log("[ProSpatium] PREFERRED_CODEC: " + config.prospatium.property_interface_config.PREFERRED_CODEC);
                        config.videoQuality = {
                            preferredCodec: config.prospatium.property_interface_config.PREFERRED_CODEC
                        }
                    }
                }
            } else {
                logger.info('[SCHEDULE_INFO] error', json);
                config.prospatium = undefined;
                isNG = true;
            }

            if (isNG) {
                return (dispatch: Dispatch<any>, _getState: Function) => {
                    dispatch(redirectToStaticPage(path));
                };
            }
        }
    }catch(error){
        logger.error('[SCHEDULE_INFO] getting information failed', error);
    }

   // ProSpatium End
    return (dispatch: Dispatch<any>, getState: Function) => {
        const { locationURL } = getState()['features/base/connection'];

        // Now that the loading of the config was successful override the values
        // with the parameters passed in the hash part of the location URI.
        // TODO We're still in the middle ground between old Web with config,
        // interfaceConfig, and loggingConfig used via global variables and new
        // Web and mobile reading the respective values from the redux store.
        // On React Native there's no interfaceConfig at all yet and
        // loggingConfig is not loaded but there's a default value in the redux
        // store.
        // Only the config will be overridden on React Native, as the other
        // globals will be undefined here. It's intentional - we do not care to
        // override those configs yet.
        locationURL
            && setConfigFromURLParams(

                // On Web the config also comes from the window.config global,
                // but it is resolved in the loadConfig procedure.
                config,
                window.interfaceConfig,
                window.loggingConfig,
                locationURL);

        // resolution
        console.log("[RESOLUTION] resolution", config.resolution);
        if(config.resolution !== undefined){
            config.constraints = {
                    video : {
                        height:{
                            ideal : config.resolution,
                            max : config.resolution,
                            min : 240
                        }
                    }
            };
            console.log("[RESOLUTION] constraints", config.constraints);
        }

        // Disable Toolbar Buttons
        console.log("[TOOLBAR] disableToolbarButtons", config.disableToolbarButtons);
        if(config.disableToolbarButtons){
            config.disableToolbarButtons.forEach((element) => {
                config.toolbarButtons = arrayRemove(config.toolbarButtons, element);
            });
        }


        dispatch({
            type: SET_CONFIG,
            config
        });
    };
}

/**
 * Stores a specific Jitsi Meet config.js object into {@code localStorage}.
 *
 * @param {string} baseURL - The base URL from which the config.js was
 * downloaded.
 * @param {Object} config - The Jitsi Meet config.js to store.
 * @returns {Function}
 */
export function storeConfig(baseURL: string, config: Object) {
    return (dispatch: Dispatch<any>) => {
        // Try to store the configuration in localStorage. If the deployment
        // specified 'getroom' as a function, for example, it does not make
        // sense to and it will not be stored.
        let b = false;

        try {
            if (typeof window.config === 'undefined' || window.config !== config) {
                jitsiLocalStorage.setItem(`${_CONFIG_STORE_PREFIX}/${baseURL}`, JSON.stringify(config));
                b = true;
            }
        } catch (e) {
            // Ignore the error because the caching is optional.
        }

        // If base/config knows a domain, then the app knows it.
        if (b) {
            try {
                dispatch(addKnownDomains(parseURIString(baseURL).host));
            } catch (e) {
                // Ignore the error because the fiddling with "known domains" is
                // a side effect here.
            }
        }

        return b;
    };
}
