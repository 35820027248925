
import {
    PROCESS_TRANSLATE_X,
    PROCESS_TRANSLATE_Y,
    PROCESS_ZOOM_IN,
    PROCESS_ZOOM_OUT,
    VISIBLE_ZOOM_IN_OUT
} from './actionTypes';
import { isRemoteTrackMuted } from '../base/tracks/functions';
import {MEDIA_TYPE} from '../base/media';

export function setVisibleZoomInOut(visible: boolean) {
    return {
        type: VISIBLE_ZOOM_IN_OUT,
        visibleZoomInOut: visible
    };
}

/**
 *
 * @param {number} processDragTranslateX
 * @returns {{
 *     type: PROCESS_TRANSLATE_X,
 *     translateX: number
 * }}
 */
export function processDragTranslateX(offset: number) {
    return (dispatch, getState) => {
        let { scale, translateX } = getState()['features/zoom'];
        const max = 50 - (50 / scale) * 100;

        translateX = translateX + (offset * (100 / scale));

        if (Math.abs(translateX) > max) {
            if (translateX > 0) {
                translateX = max;
            } else {
                translateX = -max;
            }
        }

        dispatch({
            type: PROCESS_TRANSLATE_X,
            translateX
        });
    };
}

/**
 *
 * @param {number} processDragTranslateY
 * @returns {{
 *     type: PROCESS_TRANSLATE_Y,
 *     translateY: number
 * }}
 */
export function processDragTranslateY(offset: number) {
    return (dispatch, getState) => {
        let { scale, translateY } = getState()['features/zoom'];
        const max = 50 - (50 / scale) * 100;

        translateY = translateY + (offset * (100 / scale));

        if (Math.abs(translateY) > max) {
            if (translateY > 0) {
                translateY = max;
            } else {
                translateY = -max;
            }
        }

        dispatch({
            type: PROCESS_TRANSLATE_Y,
            translateY
        });
    };
}

/**
 *
 * @param {boolean} checkTranslate
 * @returns {{
 *     type: PROCESS_TRANSLATE_Y,
 *     translateY: number
 * }}
 */
function checkTranslate(dispatch, getState, scale) {
    let { translateX, translateY } = getState()['features/zoom'];
    const max = 50 - (50 / scale) * 100;

    if (Math.abs(translateX) > max) {
        if (translateX > 0) {
            translateX = max;
        } else {
            translateX = -max;
        }
        dispatch({
            type: PROCESS_TRANSLATE_X,
            translateX
        });
    }

    if (Math.abs(translateY) > max) {
        if (translateY > 0) {
            translateY = max;
        } else {
            translateY = -max;
        }
        dispatch({
            type: PROCESS_TRANSLATE_Y,
            translateY
        });
    }
}

/**
 *
 * @param {boolean} processZoomIn
 * @returns {{
 *     type: PROCESS_ZOOM_IN,
 *     scale: number
 * }}
 */
export function processZoomIn() {
    return (dispatch, getState) => {
        let { scale } = getState()['features/zoom'];

        scale = scale + 20;
        if (scale > 500) {
            scale = 500;
        }

        checkTranslate(dispatch, getState, scale);

        dispatch({
            type: PROCESS_ZOOM_IN,
            scale
        });
    };

}

/**
 *
 * @param {boolean} processZoomOut
 * @returns {{
 *     type: PROCESS_ZOOM_OUT,
 *     scale: number
 * }}
 */
export function processZoomOut() {
    return (dispatch, getState) => {
        let { scale } = getState()['features/zoom'];

        scale = scale - 20;
        if (scale < 100) {
            scale = 100;
        }

        checkTranslate(dispatch, getState, scale);

        dispatch({
            type: PROCESS_ZOOM_OUT,
            scale
        });
    };
}
