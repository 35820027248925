// @flow

import React from 'react';
import { toArray } from 'react-emoji-render';


import { translate } from '../../../base/i18n';
import { Linkify } from '../../../base/react';
import { MESSAGE_TYPE_LOCAL,
    // Prospatium -----
    MESSAGE_TYPE_RECOGNIZE,
    MESSAGE_TYPE_TRANSLATE
    // Prospatium -----
} from '../../constants';
import AbstractChatMessage, {
    type Props
} from '../AbstractChatMessage';
import PrivateMessageButton from '../PrivateMessageButton';

// Prospatium -----
import { IconRecognizeOn, IconTranslate } from '../../../base/icons';
// Prospatium -----

/**
 * Renders a single chat message.
 */
class ChatMessage extends AbstractChatMessage<Props> {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { message, t } = this.props;
        const processedMessage = [];
        // Prospatium -----
        const isTranslated = message.otherType === MESSAGE_TYPE_TRANSLATE;
        const isRecognized = message.otherType === MESSAGE_TYPE_RECOGNIZE;
        let renderTranslateIcon = isTranslated && this._renderTranslateIcon();
        let renderRecognizeIcon = isRecognized && this._renderRecognizeIcon();
        // Prospatium -----

        // content is an array of text and emoji components
        const content = toArray(this._getMessageText(), { className: 'smiley' });

        content.forEach(i => {
            if (typeof i === 'string') {
                processedMessage.push(<Linkify key = { i }>{ i }</Linkify>);
            } else {
                processedMessage.push(i);
            }
        });

        // Prospatium -----
        let messageType = '';

        if (message.privateMessage)
        {
            messageType = 'privatemessage';
        }
        else if (isRecognized)
        {
            messageType = 'recognizemessage';
        }
        else if (isTranslated)
        {
            messageType = 'translatemessage';
        }
        // Prospatium -----

        return (
            <div
                className = 'chatmessage-wrapper'
                tabIndex = { -1 }>
                {/* Prospatium -----*/}
                <div className = { `chatmessage ${messageType}` }>
                    {/* Prospatium -----*/}
                    <div className = 'replywrapper'>
                        <div className = 'messagecontent'>
                            { this.props.showDisplayName && this._renderDisplayName() }
                            <div className = 'usermessage'>
                                <span className = 'sr-only'>
                                    { t('chat.messageAccessibleTitle',
                                        { user: this.props.message.displayName }) }
                                </span>
                                {/* Prospatium -----*/}
                                { renderTranslateIcon }
                                { renderRecognizeIcon }
                                {/* Prospatium -----*/}
                                { processedMessage }
                            </div>
                            { message.privateMessage && this._renderPrivateNotice() }
                        </div>
                        { message.privateMessage && message.messageType !== MESSAGE_TYPE_LOCAL
                            && (
                                <div className = 'messageactions'>
                                    <PrivateMessageButton
                                        participantID = { message.id }
                                        reply = { true }
                                        senderDisplayName = { message.displayName }
                                        showLabel = { false } />
                                </div>
                            ) }
                    </div>
                </div>
                { this.props.showTimestamp && this._renderTimestamp() }
            </div>
        );
    }

    _getFormattedTimestamp: () => string;

    _getMessageText: () => string;

    _getPrivateNoticeMessage: () => string;

    /**
     * Renders the display name of the sender.
     *
     * @returns {React$Element<*>}
     */
    _renderDisplayName() {
        return (
            <div
                aria-hidden = { true }
                className = 'display-name'>
                { this.props.message.displayName }
            </div>
        );
    }

    /**
     * Renders the message privacy notice.
     *
     * @returns {React$Element<*>}
     */
    _renderPrivateNotice() {
        return (
            <div className = 'privatemessagenotice'>
                { this._getPrivateNoticeMessage() }
            </div>
        );
    }

    /**
     * Renders the time at which the message was sent.
     *
     * @returns {React$Element<*>}
     */
    _renderTimestamp() {
        return (
            <div className = 'timestamp'>
                { this._getFormattedTimestamp() }
            </div>
        );
    }

    // Prospatium -----
    _renderTranslateIcon() {
        return (
            <IconTranslate width='10' height='10' />
        );
    }

    _renderRecognizeIcon() {
        return (
            <IconRecognizeOn width='10' height='10' />
        );
    }
    // Prospatium -----
}

export default translate(ChatMessage);
