// @flow

import React, { Component } from 'react';

import keyboardShortcut from '../../../../../modules/keyboardshortcut/keyboardshortcut';
import {
    ACTION_SHORTCUT_TRIGGERED,
    createShortcutEvent,
    createToolbarEvent,
    sendAnalytics
} from '../../../analytics';
import { getToolbarButtons } from '../../../base/config';
import { isToolbarButtonEnabled } from '../../../base/config/functions.web';
import { openDialog, toggleDialog } from '../../../base/dialog';
import { isMobileBrowser } from '../../../base/environment/utils';
import { translate } from '../../../base/i18n';
import JitsiMeetJS from '../../../base/lib-jitsi-meet';
import {
    getLocalParticipant,
    isLocalParticipantModerator,
    haveParticipantWithScreenSharingFeature,
    raiseHand,
    isParticipantModerator
} from '../../../base/participants';
import { connect } from '../../../base/redux';
import { getLocalVideoTrack } from '../../../base/tracks';
import { toggleChat, ChatCounter } from '../../../chat';
import { ChatButton } from '../../../chat/components';
import { DominantSpeakerName } from '../../../display-name';
import { EmbedMeetingButton } from '../../../embed-meeting';
import { SharedDocumentButton } from '../../../etherpad';
import { FeedbackButton } from '../../../feedback';
import { isVpaasMeeting } from '../../../jaas/functions';
import { KeyboardShortcutsButton } from '../../../keyboard-shortcuts';
import { LocalRecordingButton } from '../../../local-recording';
import {
    close as closeParticipantsPane,
    open as openParticipantsPane
} from '../../../participants-pane/actions';
import ParticipantsPaneButton from '../../../participants-pane/components/ParticipantsPaneButton';
import { getParticipantsPaneOpen } from '../../../participants-pane/functions';
import { addReactionToBuffer } from '../../../reactions/actions.any';
import { ReactionsMenuButton } from '../../../reactions/components';
import { REACTIONS } from '../../../reactions/constants';
import {
    LiveStreamButton,
    RecordButton
} from '../../../recording';
import {
    isScreenAudioSupported,
    isScreenVideoShared,
    ShareAudioButton,
    startScreenShareFlow
} from '../../../screen-share/';
import SecurityDialogButton from '../../../security/components/security-dialog/SecurityDialogButton';
import { SettingsButton, DeviceSettingsButtons } from '../../../settings'; //Pro:Spatium ---
import { SharedVideoButton } from '../../../shared-video/components';
import { SpeakerStatsButton } from '../../../speaker-stats';
import {
    ClosedCaptionButton
} from '../../../subtitles';
import {
    TileViewButton,
    shouldDisplayTileView,
    toggleTileView
} from '../../../video-layout';
import { VideoQualityDialog, VideoQualityButton } from '../../../video-quality/components';
import { VideoBackgroundButton } from '../../../virtual-background';
import { toggleBackgroundEffect } from '../../../virtual-background/actions';
import { VIRTUAL_BACKGROUND_TYPE } from '../../../virtual-background/constants';
import { checkBlurSupport } from '../../../virtual-background/functions';
import {
    keepToolboxVisible,
    setFullScreen,
    setOverflowMenuVisible,
    setToolbarHovered,
    showToolbox
} from '../../actions';
import { THRESHOLDS, NOT_APPLICABLE } from '../../constants';
import { isToolboxVisible } from '../../functions';
import DownloadButton from '../DownloadButton';
import HangupButton from '../HangupButton';
import HelpButton from '../HelpButton';
import MuteEveryoneButton from '../MuteEveryoneButton';
import MuteEveryonesVideoButton from '../MuteEveryonesVideoButton';

import AudioSettingsButton from './AudioSettingsButton';
import FullscreenButton from './FullscreenButton';
import OverflowMenuButton from './OverflowMenuButton';
import ProfileButton from './ProfileButton';
import RaiseHandButton from './RaiseHandButton';
import Separator from './Separator';
import ShareDesktopButton from './ShareDesktopButton';
import ToggleCameraButton from './ToggleCameraButton';
import VideoSettingsButton from './VideoSettingsButton';
//Pro:Spatium ---
import BackgroundSettingsButton from './BackgroundSettingsButton';
import { PTZ_SIZE, PtzCounter, togglePTZControl } from '../../../ptz';
import { MEETING_TYPE } from '../../../video-layout/constants';
import { OverflowMenuItem } from '../../../base/toolbox/components';
import ToolbarButton from './ToolbarButton';
import {
    IconChat,
    IconCodeBlock,
    IconExitFullScreen,
    IconFeedback,
    IconFullScreen,
    IconInviteMore,
    IconOpenInNew,
    IconPresentation,
    IconRaisedHand,
    IconRec,
    IconShareDesktop,
    IconShareVideo
} from '../../../base/icons';

import { openFeedbackDialog } from '../../../feedback';
import { openKeyboardShortcutsDialog } from '../../../keyboard-shortcuts';
import { SpeakerStats } from '../../../speaker-stats';
import { EmbedMeetingDialog } from '../../../embed-meeting';
import { toggleSharedVideo } from '../../../shared-video/actions.any.js';
import { SETTINGS_TABS, openSettingsDialog } from '../../../settings';
import { ZoomButton } from '../../../zoom/components';
import { setVisibleZoomInOut } from '../../../zoom/actions';

import logger from '../../../base/redux/logger';

import {
    getAudioInputDeviceData,
    getAudioOutputDeviceData,
} from '../../../base/devices';
//Pro:Spatium ---
/**
 * The type of the React {@code Component} props of {@link Toolbox}.
 */
type Props = {
    /**
     * True if the local participant is moderator and false otherwise.
     */
    _isLocalModerator: boolean,

    /**
     * String showing if the virtual background type is desktop-share.
     */
    _backgroundType: String,

    /**
     * Whether or not the chat feature is currently displayed.
     */
    _chatOpen: boolean,
    _ptzOpen: boolean,
    _disableToolbarButtons: Array,
    /**
     * The width of the client.
     */
    _clientWidth: number,

    /**
     * The {@code JitsiConference} for the current conference.
     */
    _conference: Object,

    /**
     * The tooltip key to use when screensharing is disabled. Or undefined
     * if non to be shown and the button to be hidden.
     */
    _desktopSharingDisabledTooltipKey: boolean,

    /**
     * Whether or not screensharing is initialized.
     */
    _desktopSharingEnabled: boolean,

    /**
     * Whether or not a dialog is displayed.
     */
    _dialog: boolean,

    /**
     * Whether or not call feedback can be sent.
     */
    _feedbackConfigured: boolean,

    /**
     * Whether or not the app is currently in full screen.
     */
    _fullScreen: boolean,

    /**
     * Whether or not the app is running in mobile browser.
     */
    _isMobile: boolean,

    /**
     * Whether or not the profile is disabled.
     */
    _isProfileDisabled: boolean,

    /**
     * Whether or not the tile view is enabled.
     */
    _tileViewEnabled: boolean,

    /**
     * Whether or not the current meeting belongs to a JaaS user.
     */
    _isVpaasMeeting: boolean,

    /**
     * The ID of the local participant.
     */
    _localParticipantID: String,

    /**
     * The JitsiLocalTrack to display.
     */
    _localVideo: Object,

    /**
     * Whether or not the overflow menu is visible.
     */
    _overflowMenuVisible: boolean,

    /**
     * Whether or not the participants pane is open.
     */
    _participantsPaneOpen: boolean,

    /**
     * Whether or not the local participant's hand is raised.
     */
    _raisedHand: boolean,

    /**
     * Whether or not the local participant is screenSharing.
     */
    _screenSharing: boolean,

    /**
     * Whether or not the local participant is sharing a YouTube video.
     */
    _sharingVideo: boolean,

    /**
     * The enabled buttons.
     */
    _toolbarButtons: Array<string>,

    /**
     * Flag showing whether toolbar is visible.
     */
    _visible: boolean,

    /**
     * Array with the buttons which this Toolbox should display.
     */
    _visibleButtons: Array<string>,

    /**
     * Returns the selected virtual source object.
     */
    _virtualSource: Object,

    /**
     * Whether or not reactions feature is enabled.
     */
    _reactionsEnabled: boolean,

    /**
     * Invoked to active other features of the app.
     */
    dispatch: Function,

    /**
     * Invoked to obtain translated strings.
     */
    t: Function,

    //Pro:Spatium ---
    _audioInputDevices: Object,
    _audioOutputDevices: Object,
    _visibleZoomInOut: boolean;
    //Pro:Spatium ---
};


//Pro:Spatium ---
type State = {

    /**
     * The width of the browser's window.
     */
    windowWidth: number
};
//Pro:Spatium ---

declare var APP: Object;

/**
 * Implements the conference toolbox on React/Web.
 *
 * @extends Component
 */
class Toolbox extends Component<Props> {
    /**
     * The long touch setTimeout handler.
     */
    timeoutHandle: Object;

    /**
     * Initializes a new {@code Toolbox} instance.
     *
     * @param {Props} props - The read-only React {@code Component} props with
     * which the new instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);

        this.timeoutHandle = null;

        // Bind event handlers so they are only bound once per instance.
        this._onFocusIn = this._onFocusIn.bind(this);
        this._onFocusOut = this._onFocusOut.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onTouchMove = this._onTouchMove.bind(this);
        this._onTouchEnd = this._onTouchEnd.bind(this);
        this._onSetOverflowVisible = this._onSetOverflowVisible.bind(this);
        this._onTabIn = this._onTabIn.bind(this);

        this._onShortcutToggleChat = this._onShortcutToggleChat.bind(this);
        this._onShortcutToggleFullScreen = this._onShortcutToggleFullScreen.bind(this);
        this._onShortcutToggleParticipantsPane = this._onShortcutToggleParticipantsPane.bind(this);
        this._onShortcutToggleRaiseHand = this._onShortcutToggleRaiseHand.bind(this);
        this._onShortcutToggleScreenshare = this._onShortcutToggleScreenshare.bind(this);
        this._onShortcutToggleVideoQuality = this._onShortcutToggleVideoQuality.bind(this);
        this._onToolbarToggleParticipantsPane = this._onToolbarToggleParticipantsPane.bind(this);
        this._onToolbarOpenVideoQuality = this._onToolbarOpenVideoQuality.bind(this);
        this._onToolbarToggleChat = this._onToolbarToggleChat.bind(this);
        this._onToolbarToggleFullScreen = this._onToolbarToggleFullScreen.bind(this);
        this._onToolbarToggleRaiseHand = this._onToolbarToggleRaiseHand.bind(this);
        this._onToolbarToggleScreenshare = this._onToolbarToggleScreenshare.bind(this);
        this._onShortcutToggleTileView = this._onShortcutToggleTileView.bind(this);
        this._onEscKey = this._onEscKey.bind(this);

        //Pro:Spatium ---
        this._onToolbarOpenSpeakerStats = this._onToolbarOpenSpeakerStats.bind(this);
        this._onToolbarOpenFeedback = this._onToolbarOpenFeedback.bind(this);
        this._onToolbarOpenKeyboardShortcuts = this._onToolbarOpenKeyboardShortcuts.bind(this);
        this._onToolbarOpenEmbedMeeting = this._onToolbarOpenEmbedMeeting.bind(this);
        this._onToolbarToggleSharedVideo = this._onToolbarToggleSharedVideo.bind(this);
        this._onToolbarToggleProfile = this._onToolbarToggleProfile.bind(this);

        this.state = {
            windowWidth: window.innerWidth
        };

        //Pro:Spatium ---

    }

    /**
     * Sets keyboard shortcuts for to trigger ToolbarButtons actions.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentDidMount() {
        const { _toolbarButtons, t, dispatch, _reactionsEnabled } = this.props;
        const KEYBOARD_SHORTCUTS = [
            isToolbarButtonEnabled('videoquality', _toolbarButtons) && {
                character: 'A',
                exec: this._onShortcutToggleVideoQuality,
                helpDescription: 'toolbar.callQuality'
            },
            isToolbarButtonEnabled('chat', _toolbarButtons) && {
                character: 'C',
                exec: this._onShortcutToggleChat,
                helpDescription: 'keyboardShortcuts.toggleChat'
            },
            isToolbarButtonEnabled('desktop', _toolbarButtons) && {
                character: 'D',
                exec: this._onShortcutToggleScreenshare,
                helpDescription: 'keyboardShortcuts.toggleScreensharing'
            },
            isToolbarButtonEnabled('participants-pane', _toolbarButtons) && {
                character: 'P',
                exec: this._onShortcutToggleParticipantsPane,
                helpDescription: 'keyboardShortcuts.toggleParticipantsPane'
            },
            isToolbarButtonEnabled('raisehand', _toolbarButtons) && {
                character: 'R',
                exec: this._onShortcutToggleRaiseHand,
                helpDescription: 'keyboardShortcuts.raiseHand'
            },
            isToolbarButtonEnabled('fullscreen', _toolbarButtons) && {
                character: 'S',
                exec: this._onShortcutToggleFullScreen,
                helpDescription: 'keyboardShortcuts.fullScreen'
            },
            isToolbarButtonEnabled('tileview', _toolbarButtons) && {
                character: 'W',
                exec: this._onShortcutToggleTileView,
                helpDescription: 'toolbar.tileViewToggle'
            }
        ];

        KEYBOARD_SHORTCUTS.forEach(shortcut => {
            if (typeof shortcut === 'object') {
                APP.keyboardshortcut.registerShortcut(
                    shortcut.character,
                    null,
                    shortcut.exec,
                    shortcut.helpDescription);
            }
        });

        if (_reactionsEnabled) {
            const REACTION_SHORTCUTS = Object.keys(REACTIONS).map(key => {
                const onShortcutSendReaction = () => {
                    dispatch(addReactionToBuffer(key));
                    sendAnalytics(createShortcutEvent(
                        `reaction.${key}`
                    ));
                };

                return {
                    character: REACTIONS[key].shortcutChar,
                    exec: onShortcutSendReaction,
                    helpDescription: t(`toolbar.reaction${key.charAt(0).toUpperCase()}${key.slice(1)}`),
                    altKey: true
                };
            });

            REACTION_SHORTCUTS.forEach(shortcut => {
                APP.keyboardshortcut.registerShortcut(
                    shortcut.character,
                    null,
                    shortcut.exec,
                    shortcut.helpDescription,
                    shortcut.altKey);
            });
        }
    }

    /**
     * Update the visibility of the {@code OverflowMenuButton}.
     *
     * @inheritdoc
     */
    componentDidUpdate(prevProps) {
        // Ensure the dialog is closed when the toolbox becomes hidden.
        if (prevProps._overflowMenuVisible && !this.props._visible) {
            this._onSetOverflowVisible(false);
        }

        if (prevProps._overflowMenuVisible
            && !prevProps._dialog
            && this.props._dialog) {
            this._onSetOverflowVisible(false);
            this.props.dispatch(setToolbarHovered(false));
        }

        // Prospatium ---
        //if(this.props._ptzOpen !== prevProps._ptzOpen){
        //   this._onResize();
        //}
        if (this.props._visibleZoomInOut
            && (this.props._tileViewEnabled || !this.props._visible)) {
            this.props.dispatch(setVisibleZoomInOut(false));
        }

        if (this.props._visibleZoomInOut
            && !prevProps._dialog
            && this.props._dialog) {
            this.props.dispatch(setVisibleZoomInOut(false));
            this.props.dispatch(setToolbarHovered(false));
        }
        // Prospatium ---
    }

    /**
     * Removes keyboard shortcuts registered by this component.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentWillUnmount() {
        ['A', 'C', 'D', 'R', 'S'].forEach(letter =>
            APP.keyboardshortcut.unregisterShortcut(letter));

        if (this.props._reactionsEnabled) {
            Object.keys(REACTIONS).map(key => REACTIONS[key].shortcutChar)
                .forEach(letter =>
                    APP.keyboardshortcut.unregisterShortcut(letter, true));
        }
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        // Prospatium ---
        const { _chatOpen, _visible, _visibleButtons, _ptzOpen, _isMobile } = this.props;
        //const { _chatOpen, _visible, _visibleButtons } = this.props;
        // Prospatium ---
        const rootClassNames = `new-toolbox${_isMobile ? '-mobile' : ''} ${_visible ? 'visible' : ''} ${_visibleButtons.length ? '' : 'no-buttons'} ${_chatOpen ? 'shift-right' : ''}`;
        // Prospatium ---

        if (_isMobile) {
            return (
                <div
                    className={rootClassNames}
                    id='new-toolbox'
                    onTouchMove={this._onTouchMove}
                    onTouchEnd={this._onTouchEnd}>
                    {this._renderToolboxContent()}
                </div>
            );
        }

        return (
            <div
                className={rootClassNames}
                id='new-toolbox'
                onFocus={this._onFocusIn}
                onBlur={this._onFocusOut}
                onMouseOut={this._onMouseOut}
                onMouseOver={this._onMouseOver}>
                {this._renderToolboxContentOldVersion()}
            </div>
        );
        // Prospatium ---
    }

    _onFocusIn() {
        this.props.dispatch(keepToolboxVisible(true));
    }

    _onFocusOut() {
        this.props.dispatch(keepToolboxVisible(false));
    }

    _onEscKey: (KeyboardEvent) => void;

    /**
     * Key handler for overflow menu.
     *
     * @param {KeyboardEvent} e - Esc key click to close the popup.
     * @returns {void}
     */
    _onEscKey(e) {
        if (e.key === 'Escape') {
            e.stopPropagation();
            this._closeOverflowMenuIfOpen();
        }
    }

    /**
     * Closes the overflow menu if opened.
     *
     * @private
     * @returns {void}
     */
    _closeOverflowMenuIfOpen() {
        const { dispatch, _overflowMenuVisible } = this.props;

        _overflowMenuVisible && dispatch(setOverflowMenuVisible(false));
    }

    /**
     * Dispatches an action to open the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _doOpenVideoQuality() {
        this.props.dispatch(openDialog(VideoQualityDialog));
    }

    /**
     * Dispatches an action to toggle the display of chat.
     *
     * @private
     * @returns {void}
     */
    _doToggleChat() {
        this.props.dispatch(toggleChat());
    }

    // Prospatium ---
    _doTogglePTZ() {
        this.props.dispatch(togglePTZControl());
    }


    /*
    _onResize() {
        if (this.props._ptzOpen){
            widthToUse -= PTZ_SIZE;
        }
    }
    */

    // Prospatium ---

    /**
     * Dispatches an action to toggle screensharing.
     *
     * @private
     * @returns {void}
     */
    _doToggleFullScreen() {
        const fullScreen = !this.props._fullScreen;

        this.props.dispatch(setFullScreen(fullScreen));
    }

    /**
     * Dispatches an action to toggle the local participant's raised hand state.
     *
     * @private
     * @returns {void}
     */
    _doToggleRaiseHand() {
        const { _localParticipantID, _raisedHand } = this.props;
        const newRaisedStatus = !_raisedHand;

        this.props.dispatch(raiseHand(newRaisedStatus));

        APP.API.notifyRaiseHandUpdated(_localParticipantID, newRaisedStatus);
    }

    /**
     * Dispatches an action to toggle screensharing.
     *
     * @private
     * @param {boolean} enabled - The state to toggle screen sharing to.
     * @param {boolean} audioOnly - Only share system audio.
     * @returns {void}
     */
    _doToggleScreenshare() {
        const {
            _backgroundType,
            _desktopSharingEnabled,
            _localVideo,
            _virtualSource,
            dispatch
        } = this.props;

        if (_backgroundType === VIRTUAL_BACKGROUND_TYPE.DESKTOP_SHARE) {
            const noneOptions = {
                enabled: false,
                backgroundType: VIRTUAL_BACKGROUND_TYPE.NONE,
                selectedThumbnail: VIRTUAL_BACKGROUND_TYPE.NONE,
                backgroundEffectEnabled: false
            };

            _virtualSource.dispose();

            dispatch(toggleBackgroundEffect(noneOptions, _localVideo));

            return;
        }

        if (_desktopSharingEnabled) {
            dispatch(startScreenShareFlow());
        }
    }

    /**
     * Dispatches an action to toggle the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _doToggleVideoQuality() {
        this.props.dispatch(toggleDialog(VideoQualityDialog));
    }

    /**
     * Dispaches an action to toggle tile view.
     *
     * @private
     * @returns {void}
     */
    _doToggleTileView() {
        this.props.dispatch(toggleTileView());
    }

    /**
     * Returns all buttons that could be rendered.
     *
     * @param {Object} state - The redux state.
     * @returns {Object} The button maps mainMenuButtons and overflowMenuButtons.
     */
    _getAllButtons() {
        const {
            _feedbackConfigured,
            _isMobile,
            _screenSharing,
            _reactionsEnabled,
            _isInspectionMode,
            _isLocalModerator
        } = this.props;

        const microphone = {
            key: 'microphone',
            Content: AudioSettingsButton,
            group: 0
        };

        const camera = {
            key: 'camera',
            Content: VideoSettingsButton,
            group: 0
        };

        const profile = this._isProfileVisible() && {
            key: 'profile',
            Content: ProfileButton,
            group: 1
        };

        const chat = {
            key: 'chat',
            Content: ChatButton,
            handleClick: this._onToolbarToggleChat,
            group: 2
        };

        const desktop = this._showDesktopSharingButton() && {
            key: 'desktop',
            Content: ShareDesktopButton,
            handleClick: this._onToolbarToggleScreenshare,
            group: 2
        };

        const raisehand = {
            key: 'raisehand',
            Content: _reactionsEnabled ? ReactionsMenuButton : RaiseHandButton,
            handleClick: _reactionsEnabled ? null : this._onToolbarToggleRaiseHand,
            group: 2
        };

        const participants = {
            key: 'participants-pane',
            alias: 'invite',
            Content: ParticipantsPaneButton,
            handleClick: this._onToolbarToggleParticipantsPane,
            group: 2
        };

        const tileview = !(_isInspectionMode && !_isLocalModerator) &&{
            key: 'tileview',
            Content: TileViewButton,
            group: 2
        };

        const toggleCamera = {
            key: 'toggle-camera',
            Content: ToggleCameraButton,
            group: 2
        };

        const videoQuality = {
            key: 'videoquality',
            Content: VideoQualityButton,
            handleClick: this._onToolbarOpenVideoQuality,
            group: 2
        };

        const fullscreen = !_isMobile && {
            key: 'fullscreen',
            Content: FullscreenButton,
            handleClick: this._onToolbarToggleFullScreen,
            group: 2
        };

        const security = {
            key: 'security',
            alias: 'info',
            Content: SecurityDialogButton,
            group: 2
        };

        const cc = {
            key: 'closedcaptions',
            Content: ClosedCaptionButton,
            group: 2
        };

        const recording = {
            key: 'recording',
            Content: RecordButton,
            group: 2
        };

        const localRecording = {
            key: 'localrecording',
            Content: LocalRecordingButton,
            group: 2
        };

        const livestreaming = {
            key: 'livestreaming',
            Content: LiveStreamButton,
            group: 2
        };

        const muteEveryone = {
            key: 'mute-everyone',
            Content: MuteEveryoneButton,
            group: 2
        };

        const muteVideoEveryone = {
            key: 'mute-video-everyone',
            Content: MuteEveryonesVideoButton,
            group: 2
        };

        const shareVideo = {
            key: 'sharedvideo',
            Content: SharedVideoButton,
            group: 3
        };

        const shareAudio = this._showAudioSharingButton() && {
            key: 'shareaudio',
            Content: ShareAudioButton,
            group: 3
        };

        const etherpad = {
            key: 'etherpad',
            Content: SharedDocumentButton,
            group: 3
        };

        const virtualBackground = !_screenSharing && checkBlurSupport() && {
            key: 'select-background',
            Content: VideoBackgroundButton,
            group: 3
        };

        const speakerStats = {
            key: 'stats',
            Content: SpeakerStatsButton,
            group: 3
        };

        const settings = {
            key: 'settings',
            Content: SettingsButton,
            group: 4
        };

        const shortcuts = !_isMobile && keyboardShortcut.getEnabled() && {
            key: 'shortcuts',
            Content: KeyboardShortcutsButton,
            group: 4
        };

        const embed = this._isEmbedMeetingVisible() && {
            key: 'embedmeeting',
            Content: EmbedMeetingButton,
            group: 4
        };

        const feedback = _feedbackConfigured && {
            key: 'feedback',
            Content: FeedbackButton,
            group: 4
        };

        const download = {
            key: 'download',
            Content: DownloadButton,
            group: 4
        };

        const help = {
            key: 'help',
            Content: HelpButton,
            group: 4
        };

        return {
            microphone,
            camera,
            profile,
            desktop,
            chat,
            raisehand,
            participants,
            tileview,
            toggleCamera,
            videoQuality,
            fullscreen,
            security,
            cc,
            recording,
            localRecording,
            livestreaming,
            muteEveryone,
            muteVideoEveryone,
            shareVideo,
            shareAudio,
            etherpad,
            virtualBackground,
            speakerStats,
            settings,
            shortcuts,
            embed,
            feedback,
            download,
            help
        };
    }

    /**
     * Returns all buttons that need to be rendered.
     *
     * @param {Object} state - The redux state.
     * @returns {Object} The visible buttons arrays .
     */
    _getVisibleButtons() {
        const {
            _clientWidth,
            _toolbarButtons
        } = this.props;


        const buttons = this._getAllButtons();
        const isHangupVisible = isToolbarButtonEnabled('hangup', _toolbarButtons);
        const { order } = THRESHOLDS.find(({ width }) => _clientWidth > width)
            || THRESHOLDS[THRESHOLDS.length - 1];
        let sliceIndex = order.length + 2;

        const keys = Object.keys(buttons);

        const filtered = [
            ...order.map(key => buttons[key]),
            ...Object.values(buttons).filter((button, index) => !order.includes(keys[index]))
        ].filter(Boolean).filter(({ key, alias = NOT_APPLICABLE }) =>
            isToolbarButtonEnabled(key, _toolbarButtons) || isToolbarButtonEnabled(alias, _toolbarButtons));

        if (isHangupVisible) {
            sliceIndex -= 1;
        }

        // This implies that the overflow button will be displayed, so save some space for it.
        if (sliceIndex < filtered.length) {
            sliceIndex -= 1;
        }

        return {
            mainMenuButtons: filtered.slice(0, sliceIndex),
            overflowMenuButtons: filtered.slice(sliceIndex)
        };
    }

    _onMouseOut: () => void;

    /**
     * Dispatches an action signaling the toolbar is not being hovered.
     *
     * @private
     * @returns {void}
     */
    _onMouseOut() {
        this.props.dispatch(setToolbarHovered(false));
    }

    _onMouseOver: () => void;

    /**
     * Dispatches an action signaling the toolbar is being hovered.
     *
     * @private
     * @returns {void}
     */
    _onMouseOver() {
        this.props.dispatch(setToolbarHovered(true));
    }

    _onTouchMove: () => void;

    /**
     * Dispatches an action signaling the toolbar is being hovered.
     *
     * @private
     * @returns {void}
     */
    _onTouchMove() {
        this.props.dispatch(setToolbarHovered(true));
        clearTimeout(this.timeoutHandle);
        console.log('_onTouchMove');
    }

    _onTouchEnd: () => void;

    _onTouchEnd() {
        let timeoutMS = 5000;
        this.timeoutHandle = setTimeout(() => {
            this.props.dispatch(setToolbarHovered(false))
        }, timeoutMS);
        console.log('_onTouchEnd');
    }

    _onSetOverflowVisible: (boolean) => void;

    /**
     * Sets the visibility of the overflow menu.
     *
     * @param {boolean} visible - Whether or not the overflow menu should be
     * displayed.
     * @private
     * @returns {void}
     */
    _onSetOverflowVisible(visible) {
        this.props.dispatch(setOverflowMenuVisible(visible));
    }

    _onShortcutToggleChat: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling the display of chat.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleChat() {
        sendAnalytics(createShortcutEvent(
            'toggle.chat',
            {
                enable: !this.props._chatOpen
            }));

        // Checks if there was any text selected by the user.
        // Used for when we press simultaneously keys for copying
        // text messages from the chat board
        if (window.getSelection().toString() !== '') {
            return false;
        }

        this._doToggleChat();
    }

    _onShortcutToggleParticipantsPane: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling the display of the participants pane.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleParticipantsPane() {
        sendAnalytics(createShortcutEvent(
            'toggle.participants-pane',
            {
                enable: !this.props._participantsPaneOpen
            }));

        this._onToolbarToggleParticipantsPane();
    }

    _onShortcutToggleVideoQuality: () => void;

    /**
    * Creates an analytics keyboard shortcut event and dispatches an action for
    * toggling the display of Video Quality.
    *
    * @private
    * @returns {void}
    */
    _onShortcutToggleVideoQuality() {
        sendAnalytics(createShortcutEvent('video.quality'));

        this._doToggleVideoQuality();
    }

    _onShortcutToggleTileView: () => void;

    /**
     * Dispatches an action for toggling the tile view.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleTileView() {
        sendAnalytics(createShortcutEvent(
            'toggle.tileview',
            {
                enable: !this.props._tileViewEnabled
            }));

        this._doToggleTileView();
    }

    _onShortcutToggleFullScreen: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling full screen mode.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleFullScreen() {
        sendAnalytics(createShortcutEvent(
            'toggle.fullscreen',
            {
                enable: !this.props._fullScreen
            }));

        this._doToggleFullScreen();
    }

    _onShortcutToggleRaiseHand: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling raise hand.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleRaiseHand() {
        sendAnalytics(createShortcutEvent(
            'toggle.raise.hand',
            ACTION_SHORTCUT_TRIGGERED,
            { enable: !this.props._raisedHand }));

        this._doToggleRaiseHand();
    }

    _onShortcutToggleScreenshare: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling screensharing.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleScreenshare() {
        sendAnalytics(createShortcutEvent(
            'toggle.screen.sharing',
            ACTION_SHORTCUT_TRIGGERED,
            {
                enable: !this.props._screenSharing
            }));

        this._doToggleScreenshare();
    }

    _onTabIn: () => void;

    /**
     * Toggle the toolbar visibility when tabbing into it.
     *
     * @returns {void}
     */
    _onTabIn() {
        if (!this.props._visible) {
            this.props.dispatch(showToolbox());
        }
    }
    _onToolbarToggleParticipantsPane: () => void;

    /**
     * Dispatches an action for toggling the participants pane.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleParticipantsPane() {
        const { dispatch, _participantsPaneOpen } = this.props;

        if (_participantsPaneOpen) {
            dispatch(closeParticipantsPane());
        } else {
            dispatch(openParticipantsPane());
        }
    }

    _onToolbarOpenVideoQuality: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * open the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenVideoQuality() {
        sendAnalytics(createToolbarEvent('video.quality'));

        this._doOpenVideoQuality();
    }

    _onToolbarToggleChat: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * the display of chat.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleChat() {
        console.log("click ---- Chat");
        sendAnalytics(createToolbarEvent(
            'toggle.chat',
            {
                enable: !this.props._chatOpen
            }));
        this._closeOverflowMenuIfOpen();
        this._doToggleChat();
    }

    _onToolbarToggleFullScreen: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * full screen mode.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleFullScreen() {
        sendAnalytics(createToolbarEvent(
            'toggle.fullscreen',
            {
                enable: !this.props._fullScreen
            }));
        this._closeOverflowMenuIfOpen();
        this._doToggleFullScreen();
    }

    _onToolbarToggleRaiseHand: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * raise hand.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleRaiseHand() {
        sendAnalytics(createToolbarEvent(
            'raise.hand',
            { enable: !this.props._raisedHand }));

        this._doToggleRaiseHand();
    }

    _onToolbarToggleScreenshare: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * screensharing.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleScreenshare() {
        sendAnalytics(createToolbarEvent(
            'toggle.screen.sharing',
            ACTION_SHORTCUT_TRIGGERED,
            { enable: !this.props._screenSharing }));

        this._closeOverflowMenuIfOpen();
        this._doToggleScreenshare();
    }

    /**
     * Returns true if the audio sharing button should be visible and
     * false otherwise.
     *
     * @returns {boolean}
     */
    _showAudioSharingButton() {
        const {
            _desktopSharingEnabled
        } = this.props;

        return _desktopSharingEnabled && isScreenAudioSupported();
    }

    /**
     * Returns true if the desktop sharing button should be visible and
     * false otherwise.
     *
     * @returns {boolean}
     */
    _showDesktopSharingButton() {
        const {
            _desktopSharingEnabled,
            _desktopSharingDisabledTooltipKey
        } = this.props;

        return _desktopSharingEnabled || _desktopSharingDisabledTooltipKey;
    }

    /**
     * Returns true if the embed meeting button is visible and false otherwise.
     *
     * @returns {boolean}
     */
    _isEmbedMeetingVisible() {
        // prospatium --
        return false;
        // prospatium --
    }

    /**
     * Returns true if the profile button is visible and false otherwise.
     *
     * @returns {boolean}
     */
    _isProfileVisible() {
        return !this.props._isProfileDisabled;
    }

    /**
     * Renders the toolbox content.
     *
     * @returns {ReactElement}
     */
    _renderToolboxContent() {
        const {
            _isMobile,
            _overflowMenuVisible,
            _toolbarButtons,
            //Pro:Spatium ---
            _ptzOpen,
            _audioInputDevices,
            _audioOutputDevices,
            //Pro:Spatium ---
            t,
            _reactionsEnabled
        } = this.props;

        const toolbarAccLabel = 'toolbar.accessibilityLabel.moreActionsMenu';
        const containerClassName = `toolbox-content${_isMobile ? ' toolbox-content-mobile' : ''}`;

        const { mainMenuButtons, overflowMenuButtons } = this._getVisibleButtons();

        return (
            <div className={containerClassName}>
                <div
                    className='toolbox-content-wrapper'
                    onFocus={this._onTabIn}
                    {...(_isMobile ? {} : {
                        onMouseOut: this._onMouseOut,
                        onMouseOver: this._onMouseOver,
                        onTouchMove: this._onTouchMove,
                        onTouchEnd: this._onTouchEnd
                    })}>
                    <DominantSpeakerName />
                    <div className='toolbox-content-items'>
                        {mainMenuButtons.map(({ Content, key, ...rest }) => Content !== Separator && (
                            <Content
                                {...rest}
                                key={key} />))}

                        {Boolean(overflowMenuButtons.length) && (
                            <OverflowMenuButton
                                ariaControls='overflow-menu'
                                isOpen={_overflowMenuVisible}
                                key='overflow-menu'
                                onVisibilityChange={this._onSetOverflowVisible}
                                showMobileReactions={
                                    _reactionsEnabled && overflowMenuButtons.find(({ key }) => key === 'raisehand')
                                }>
                                <ul
                                    aria-label={t(toolbarAccLabel)}
                                    className='overflow-menu'
                                    id='overflow-menu'
                                    onKeyDown={this._onEscKey}
                                    role='menu'>
                                    {overflowMenuButtons.map(({ group, key, Content, ...rest }, index, arr) => {
                                        const showSeparator = index > 0 && arr[index - 1].group !== group;

                                        return (key !== 'raisehand' || !_reactionsEnabled)
                                            && <>
                                                {showSeparator && <Separator key={`hr${group}`} />}
                                                <Content
                                                    {...rest}
                                                    key={key}
                                                    showLabel={true} />
                                            </>
                                            ;
                                    })}

                                    { // Prospatium -----
                                        Boolean(_audioInputDevices.length) && (
                                            <>
                                                <Separator key={`hrAudioInput`} />
                                                <label
                                                    className='device-selector-label'>
                                                    {this.props.t('settings.selectMic')}
                                                </label>

                                                {_audioInputDevices.map((device) => {
                                                    return <DeviceSettingsButtons
                                                        label={device.label}
                                                        deviceId={device.deviceId}
                                                        isInput={true}
                                                        showLabel={true}
                                                    />;
                                                })}
                                            </>)
                                    }

                                    {Boolean(_audioOutputDevices.length) && (
                                        <>
                                            <Separator key={`hrAudioOutput`} />
                                            <label
                                                className='device-selector-label'>
                                                {this.props.t('settings.selectAudioOutput')}
                                            </label>

                                            {_audioOutputDevices.map((device) => {
                                                return <DeviceSettingsButtons
                                                    label={device.label}
                                                    deviceId={device.deviceId}
                                                    isInput={false}
                                                    showLabel={true}
                                                />;
                                            })}
                                        </>)
                                        // Prospatium -----
                                    }
                                </ul>
                            </OverflowMenuButton>
                        )}

                        <HangupButton
                            customClass='hangup-button'
                            key='hangup-button'
                            visible={isToolbarButtonEnabled('hangup', _toolbarButtons)} />
                    </div>
                </div>
            </div>
        );
    }

    //Pro:Spatium ---
    /**
     * Renders the toolbox content.
     *
     * @returns {ReactElement}
     */
    _renderToolboxContentOldVersion() {
        const {
            _chatOpen,
            _overflowMenuVisible,
            _raisedHand,
            _ptzOpen,
            _disableToolbarButtons,
            _isInspectionMode,
            _localParticipant,
            t
        } = this.props;
        const overflowMenuContent = this._renderOverflowMenuContent();
        const overflowHasItems = Boolean(overflowMenuContent.filter(child => child).length);
        const toolbarAccLabel = 'toolbar.accessibilityLabel.moreActionsMenu';
        const buttonsLeft = [];
        const buttonsRight = [];

        const smallThreshold = 700;
        const verySmallThreshold = 500;

        let minSpaceBetweenButtons = 48;
        let widthPlusPaddingOfButton = 56;

        if (this.state.windowWidth <= verySmallThreshold) {
            minSpaceBetweenButtons = 26;
            widthPlusPaddingOfButton = 28;
        } else if (this.state.windowWidth <= smallThreshold) {
            minSpaceBetweenButtons = 36;
            widthPlusPaddingOfButton = 40;
        }

        const maxNumberOfButtonsPerGroup = Math.floor(
            (
                this.state.windowWidth
                - 168 // the width of the central group by design
                - minSpaceBetweenButtons // the minimum space between the button groups
            )
            / widthPlusPaddingOfButton // the width + padding of a button
            / 2 // divide by the number of groups(left and right group)
        );

        const showOverflowMenu = this.state.windowWidth >= verySmallThreshold || isMobileBrowser();

        if (this._shouldShowButton('chat')) {
            buttonsLeft.push('chat');
        }
        if (this._shouldShowButton('zoom')) {
            buttonsLeft.push('zoom');
        }
        if (this._shouldShowButton('desktop')
            && this._showDesktopSharingButton()) {
            buttonsLeft.push('desktop');
        }
        if (this._shouldShowButton('raisehand')) {
            buttonsLeft.push('raisehand');
        }
        if (this._shouldShowButton('closedcaptions')) {
            buttonsLeft.push('closedcaptions');
        }
        if (overflowHasItems && showOverflowMenu) {
            // Prospatium -----
            if (_disableToolbarButtons === null || typeof _disableToolbarButtons === 'undefined'
                || (typeof _disableToolbarButtons !== 'undefined' && !_disableToolbarButtons.includes('more-actions'))) {
                // Prospatium -----
                buttonsRight.push('overflowmenu');
            }
        }
        if (this._shouldShowButton('invite')) {
            buttonsRight.push('invite');
        }
        if (this._shouldShowButton('security') || this._shouldShowButton('info')) {
            buttonsRight.push('security');
        }

        if (this._shouldShowButton('tileview')) {
            buttonsRight.push('tileview');
        }
        if (this._shouldShowButton('localrecording')) {
            buttonsRight.push('localrecording');
        }

        const movedButtons = [];

        if (buttonsLeft.length > maxNumberOfButtonsPerGroup) {
            movedButtons.push(...buttonsLeft.splice(
                maxNumberOfButtonsPerGroup,
                buttonsLeft.length - maxNumberOfButtonsPerGroup));
            if (buttonsRight.indexOf('overflowmenu') === -1 && showOverflowMenu) {
                buttonsRight.unshift('overflowmenu');
            }
        }

        if (buttonsRight.length > maxNumberOfButtonsPerGroup) {
            if (buttonsRight.indexOf('overflowmenu') === -1 && showOverflowMenu) {
                buttonsRight.unshift('overflowmenu');
            }

            let numberOfButtons = maxNumberOfButtonsPerGroup;

            // make sure the more button will be displayed when we move buttons.
            if (numberOfButtons === 0) {
                numberOfButtons++;
            }

            movedButtons.push(...buttonsRight.splice(
                numberOfButtons,
                buttonsRight.length - numberOfButtons));

        }

        overflowMenuContent.splice(
            1, 0, ...this._renderMovedButtons(movedButtons));

        return (
            <div className='toolbox-content'>
                <div className='button-group-left'>
                    {buttonsLeft.indexOf('chat') !== -1
                        && <div className='toolbar-button-with-badge'>
                            <ToolbarButton
                                accessibilityLabel={t('toolbar.accessibilityLabel.chat')}
                                icon={IconChat}
                                onClick={this._onToolbarToggleChat}
                                toggled={_chatOpen}
                                tooltip={t('toolbar.chat')} />
                            <ChatCounter />
                        </div>}
                    {buttonsLeft.indexOf('zoom') !== -1
                        && !this.props._tileViewEnabled
                        && !isMobileBrowser()
                        && !(_isInspectionMode && !isParticipantModerator(_localParticipant))
                        && <ZoomButton />}
                    {buttonsLeft.indexOf('desktop') !== -1
                        && this._renderDesktopSharingButton()}
                    {buttonsLeft.indexOf('raisehand') !== -1
                        && <ToolbarButton
                            accessibilityLabel={t('toolbar.accessibilityLabel.raiseHand')}
                            icon={IconRaisedHand}
                            onClick={this._onToolbarToggleRaiseHand}
                            toggled={_raisedHand}
                            tooltip={t('toolbar.raiseHand')} />}
                    {
                        buttonsLeft.indexOf('closedcaptions') !== -1
                        && <ClosedCaptionButton />
                    }

                </div>
                <div className='button-group-center'>
                    {this._renderAudioButton()}
                    <HangupButton
                        customClass='hangup-button'
                        visible={this._shouldShowButton('hangup')} />
                    {this._renderVideoButton()}
                    {this._renderBackgroundButton()}
                </div>
                <div className='button-group-right'>
                    {buttonsRight.indexOf('localrecording') !== -1
                        && <LocalRecordingButton
                            onClick={
                                this._onToolbarOpenLocalRecordingInfoDialog
                            } />
                    }
                    {buttonsRight.indexOf('tileview') !== -1
                        && !(_isInspectionMode && !isParticipantModerator(_localParticipant))
                        && <TileViewButton />}
                    {buttonsRight.indexOf('invite') !== -1
                        && <ToolbarButton
                            accessibilityLabel=
                            {t('toolbar.accessibilityLabel.invite')}
                            icon={IconInviteMore}
                            onClick={this._onToolbarOpenInvite}
                            tooltip={t('toolbar.invite')} />}
                    {buttonsRight.indexOf('security') !== -1
                        && <SecurityDialogButton customClass='security-toolbar-button' />}
                    {buttonsRight.indexOf('overflowmenu') !== -1
                        && <OverflowMenuButton
                            isOpen={_overflowMenuVisible}
                            onVisibilityChange={this._onSetOverflowVisible}>
                            <ul
                                aria-label={t(toolbarAccLabel)}
                                className='overflow-menu'>
                                {overflowMenuContent}
                            </ul>
                        </OverflowMenuButton>}
                </div>
            </div>);
    }

    /**
     * Renders the list elements of the overflow menu.
     *
     * @private
     * @returns {Array<ReactElement>}
     */
    _renderOverflowMenuContent() {
        const {
            _feedbackConfigured,
            _fullScreen,
            _screensharing,
            _sharingVideo,
            _isLocalModerator,
            t
        } = this.props;

        return [
            this._isProfileVisible()
            && <ProfileButton
                key='profile'
                onClick={this._onToolbarToggleProfile} />,
            this._shouldShowButton('videoquality')
            && <VideoQualityButton
                key='videoquality'
                onClick={this._onToolbarOpenVideoQuality} />,
            this._shouldShowButton('fullscreen')
            && <OverflowMenuItem
                accessibilityLabel={t('toolbar.accessibilityLabel.fullScreen')}
                icon={_fullScreen ? IconExitFullScreen : IconFullScreen}
                key='fullscreen'
                onClick={this._onToolbarToggleFullScreen}
                text={_fullScreen ? t('toolbar.exitFullScreen') : t('toolbar.enterFullScreen')} />,
            <LiveStreamButton
                key='livestreaming'
                showLabel={true} />,
            <RecordButton
                key='record'
                showLabel={true} />,
            this._shouldShowButton('sharedvideo')
            && <OverflowMenuItem
                accessibilityLabel={t('toolbar.accessibilityLabel.sharedvideo')}
                icon={IconShareVideo}
                key='sharedvideo'
                onClick={this._onToolbarToggleSharedVideo}
                text={_sharingVideo ? t('toolbar.stopSharedVideo') : t('toolbar.sharedvideo')} />,
            this._shouldShowButton('etherpad')
            && <SharedDocumentButton
                key='etherpad'
                showLabel={true} />,

            <SettingsButton
                key='settings'
                showLabel={true}
                visible={this._shouldShowButton('settings')} />,
            _isLocalModerator &&
            <MuteEveryoneButton
                key='mute-everyone'
                showLabel={true}
                visible={this._shouldShowButton('mute-everyone')} />,
            this._shouldShowButton('stats')
            && <OverflowMenuItem
                accessibilityLabel={t('toolbar.accessibilityLabel.speakerStats')}
                icon={IconPresentation}
                key='stats'
                onClick={this._onToolbarOpenSpeakerStats}
                text={t('toolbar.speakerStats')} />,
            this._isEmbedMeetingVisible()
            && <OverflowMenuItem
                accessibilityLabel={t('toolbar.accessibilityLabel.embedMeeting')}
                icon={IconCodeBlock}
                key='embed'
                onClick={this._onToolbarOpenEmbedMeeting}
                text={t('toolbar.embedMeeting')} />,
            this._shouldShowButton('feedback')
            && _feedbackConfigured
            && <OverflowMenuItem
                accessibilityLabel={t('toolbar.accessibilityLabel.feedback')}
                icon={IconFeedback}
                key='feedback'
                onClick={this._onToolbarOpenFeedback}
                text={t('toolbar.feedback')} />,
            this._shouldShowButton('shortcuts')
            && <OverflowMenuItem
                accessibilityLabel={t('toolbar.accessibilityLabel.shortcuts')}
                icon={IconOpenInNew}
                key='shortcuts'
                onClick={this._onToolbarOpenKeyboardShortcuts}
                text={t('toolbar.shortcuts')} />,
            this._shouldShowButton('download')
            && <DownloadButton
                key='download'
                showLabel={true} />,
            this._shouldShowButton('help')
            && <HelpButton
                key='help'
                showLabel={true} />
        ];
    }
    _shouldShowButton: (string) => boolean;

    _shouldShowButton(buttonName) {
        return this.props._visibleButtons.indexOf(buttonName) !== -1;
    }

    _renderMovedButtons(movedButtons) {
        const {
            _chatOpen,
            _raisedHand,
            _ptzOpen,
            t
        } = this.props;

        return movedButtons.map(buttonName => {
            switch (buttonName) {
                case 'desktop':
                    return this._renderDesktopSharingButton(true);
                case 'raisehand':
                    return (
                        <OverflowMenuItem
                            accessibilityLabel=
                            {t('toolbar.accessibilityLabel.raiseHand')}
                            icon={IconRaisedHand}
                            key='raisedHand'
                            onClick={this._onToolbarToggleRaiseHand}
                            text={
                                t(`toolbar.${_raisedHand
                                        ? 'lowerYourHand' : 'raiseYourHand'}`
                                )
                            } />
                    );
                case 'chat':
                    return (
                        <OverflowMenuItem
                            accessibilityLabel=
                            {t('toolbar.accessibilityLabel.chat')}
                            icon={IconChat}
                            key='chat'
                            onClick={this._onToolbarToggleChat}
                            text={
                                t(`toolbar.${_chatOpen ? 'closeChat' : 'openChat'}`
                                )
                            } />
                    );
                case 'closedcaptions':
                    return (
                        <ClosedCaptionButton
                            key='closed-captions'
                            showLabel={true} />
                    );
                case 'security':
                    return (
                        <SecurityDialogButton
                            key='security'
                            showLabel={true} />
                    );
                case 'invite':
                    return (
                        <OverflowMenuItem
                            accessibilityLabel={t('toolbar.accessibilityLabel.invite')}
                            icon={IconInviteMore}
                            key='invite'
                            onClick={this._onToolbarOpenInvite}
                            text={t('toolbar.invite')} />
                    );
                case 'tileview':
                    return <TileViewButton showLabel={true} />;
                case 'localrecording':
                    return (
                        <OverflowMenuItem
                            accessibilityLabel={t('toolbar.accessibilityLabel.localRecording')}
                            icon={IconRec}
                            key='localrecording'
                            onClick={this._onToolbarOpenLocalRecordingInfoDialog}
                            text={t('localRecording.dialogTitle')} />
                    );
                default:
                    return null;
            }
        });

    }

    _renderAudioButton() {
        return this._shouldShowButton('microphone')
            ? <AudioSettingsButton
                key='asb'
                visible={true} />
            : null;
    }

    _renderVideoButton() {
        return this._shouldShowButton('camera')
            ? <VideoSettingsButton
                key='vsb'
                visible={true} />
            : null;
    }

    _renderBackgroundButton() {
        return this._shouldShowButton('setbackground')
            ? <BackgroundSettingsButton
                key='bsb'
                visible={true} />
            : null;
    }

    _renderDesktopSharingButton(isInOverflowMenu = false) {
        const {
            _desktopSharingEnabled,
            _desktopSharingDisabledTooltipKey,
            _screensharing,
            t
        } = this.props;

        if (!this._showDesktopSharingButton()) {
            return null;
        }

        if (isInOverflowMenu) {
            return (
                <OverflowMenuItem
                    accessibilityLabel
                    ={t('toolbar.accessibilityLabel.shareYourScreen')}
                    disabled={_desktopSharingEnabled}
                    icon={IconShareDesktop}
                    iconId='share-desktop'
                    key='desktop'
                    onClick={this._onToolbarToggleScreenshare}
                    text={
                        t(`toolbar.${_screensharing
                                ? 'stopScreenSharing' : 'startScreenSharing'}`
                        )
                    } />
            );
        }

        const tooltip = t(
            _desktopSharingEnabled
                ? 'dialog.shareYourScreen' : _desktopSharingDisabledTooltipKey);

        return (
            <ToolbarButton
                accessibilityLabel
                ={t('toolbar.accessibilityLabel.shareYourScreen')}
                disabled={!_desktopSharingEnabled}
                icon={IconShareDesktop}
                onClick={this._onToolbarToggleScreenshare}
                toggled={_screensharing}
                tooltip={tooltip} />
        );
    }

    _onToolbarOpenSpeakerStats: () => void;
    _onToolbarOpenSpeakerStats() {
        sendAnalytics(createToolbarEvent('speaker.stats'));

        this._doOpenSpeakerStats();
    }

    _doOpenSpeakerStats() {
        this.props.dispatch(openDialog(SpeakerStats, {
            conference: this.props._conference
        }));
    }

    _onToolbarOpenFeedback: () => void;
    _onToolbarOpenFeedback() {
        sendAnalytics(createToolbarEvent('feedback'));

        this._doOpenFeedback();
    }
    _doOpenFeedback() {
        const { _conference } = this.props;

        this.props.dispatch(openFeedbackDialog(_conference));
    }

    _onToolbarOpenKeyboardShortcuts: () => void;

    _onToolbarOpenKeyboardShortcuts() {
        sendAnalytics(createToolbarEvent('shortcuts'));

        this._doOpenKeyboardShorcuts();
    }
    _doOpenKeyboardShorcuts() {
        this.props.dispatch(openKeyboardShortcutsDialog());
    }

    _onToolbarOpenEmbedMeeting: () => void;

    _onToolbarOpenEmbedMeeting() {
        sendAnalytics(createToolbarEvent('embed.meeting'));

        this._doOpenEmbedMeeting();
    }
    _doOpenEmbedMeeting() {
        this.props.dispatch(openDialog(EmbedMeetingDialog));
    }

    _onToolbarToggleSharedVideo: () => void;
    _onToolbarToggleSharedVideo() {
        sendAnalytics(createToolbarEvent('shared.video.toggled',
            {
                enable: !this.props._sharingVideo
            }));

        this._doToggleSharedVideo();
    }
    _doToggleSharedVideo() {
        this.props.dispatch(toggleSharedVideo());
    }

    _onToolbarToggleProfile: () => void;

    _onToolbarToggleProfile() {
        sendAnalytics(createToolbarEvent('profile'));

        this._doToggleProfile();
    }
    _doToggleProfile() {
        this.props.dispatch(openSettingsDialog(SETTINGS_TABS.PROFILE));
    }





    //Pro:Spatium ---
}

/**
 * Maps (parts of) the redux state to {@link Toolbox}'s React {@code Component}
 * props.
 *
 * @param {Object} state - The redux store/state.
 * @private
 * @returns {{}}
 */
function _mapStateToProps(state) {
    const { conference } = state['features/base/conference'];
    const { prospatium } = state['features/base/config'];
    const isInspectionMode = prospatium.schedule_general_config?.PS_MEETING_TYPE === MEETING_TYPE.INSPECTION;
    let desktopSharingEnabled = JitsiMeetJS.isDesktopSharingEnabled();
    const {
        callStatsID,
        enableFeaturesBasedOnToken
    } = state['features/base/config'];
    const config = state['features/base/config'];
    const {
        fullScreen,
        overflowMenuVisible
    } = state['features/toolbox'];
    const localParticipant = getLocalParticipant(state);
    const localVideo = getLocalVideoTrack(state['features/base/tracks']);
    const { clientWidth } = state['features/base/responsive-ui'];
    const { enableReactions } = state['features/base/config'];
    const _isLocalModerator = isLocalParticipantModerator(state);
    // Prospatium -----
    const audioInputDevices = getAudioInputDeviceData(state);
    const audioOutputDevices = getAudioOutputDeviceData(state);
    const showAudioOutputSelect = !!JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output');
    const { visibleZoomInOut } = state['features/zoom'];
    // Prospatium -----

    let desktopSharingDisabledTooltipKey;

    if (enableFeaturesBasedOnToken) {
        if (desktopSharingEnabled) {
            // we enable desktop sharing if any participant already have this
            // feature enabled and if the user supports it.
            desktopSharingEnabled = haveParticipantWithScreenSharingFeature(state);
            desktopSharingDisabledTooltipKey = 'dialog.shareYourScreenDisabled';
        }
    }

    return {
        _chatOpen: state['features/chat'].isOpen,
        _clientWidth: clientWidth,
        _conference: conference,
        _desktopSharingEnabled: desktopSharingEnabled,
        _backgroundType: state['features/virtual-background'].backgroundType,
        _virtualSource: state['features/virtual-background'].virtualSource,
        _desktopSharingDisabledTooltipKey: desktopSharingDisabledTooltipKey,
        _dialog: Boolean(state['features/base/dialog'].component),
        _feedbackConfigured: Boolean(callStatsID),
        _isProfileDisabled: Boolean(state['features/base/config'].disableProfile),
        _isMobile: isMobileBrowser(),
        _isVpaasMeeting: isVpaasMeeting(state),
        _fullScreen: fullScreen,
        _tileViewEnabled: shouldDisplayTileView(state),
        _localParticipantID: localParticipant?.id,
        _localParticipant: localParticipant,
        _localVideo: localVideo,
        _overflowMenuVisible: overflowMenuVisible,
        _participantsPaneOpen: getParticipantsPaneOpen(state),
        _raisedHand: localParticipant?.raisedHand,
        _screenSharing: isScreenVideoShared(state),
        _toolbarButtons: getToolbarButtons(state),
        _visible: isToolboxVisible(state),
        _visibleButtons: getToolbarButtons(state),
        _ptzOpen: state['features/ptz'].isOpen,
        // Prospatium -----
        _disableToolbarButtons: config.disableToolbarButtons || [],
        // Prospatium -----
        _reactionsEnabled: enableReactions,
        // Prospatium -----
        _audioInputDevices: audioInputDevices,
        _audioOutputDevices: showAudioOutputSelect ? audioOutputDevices : [],
        _isLocalModerator,
        _visibleZoomInOut: visibleZoomInOut,
        _isInspectionMode: isInspectionMode
        // Prospatium -----
    };
}

export default translate(connect(_mapStateToProps)(Toolbox));
