// @flow

import React, { Component } from 'react';
//import Emoji from 'react-emoji-render';
//import TextareaAutosize from 'react-textarea-autosize';
import type { Dispatch } from 'redux';

import { translate } from '../../../base/i18n';
import { connect } from '../../../base/redux';

//import ActionButton from './ActionButton';
//import Button, { ButtonGroup } from '@atlaskit/button';
import { PTZ_SLIDER_SCALE } from '../../constants';
import { Icon, IconPtzArrow, IconPtzReset, IconPtzPlus, IconPtzMinus } from '../../../base/icons';
/**
 * The type of the React {@code Component} props of {@link PtzInput}.
 */
type Props = {

    /**
     * Invoked to send chat messages.
     */
    dispatch: Dispatch<any>,

    /**
     * Callback to invoke on message send.
     */
    onSend: Function,

    /**
     * Invoked to obtain translated strings.
     */
    t: Function
};

/**
 * The type of the React {@code Component} state of {@link PtzInput}.
 */
type State = {

    /**
     * User provided nickname when the input text is provided in the view.
     */
    message: string,
    ptzLevel: number
};

/**
 * Implements a React Component for drafting and submitting a chat message.
 *
 * @extends Component
 */
class PtzInput extends Component<Props, State> {
    _textArea: ?HTMLTextAreaElement;

    state = {
        message: '',
        ptzLevel: 0
    };

    /**
     * Initializes a new {@code PtzInput} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);

        this._textArea = null;

        // Bind event handlers so they are only bound once for every instance.
        this._onMessageChange = this._onMessageChange.bind(this);
        this._onPtzInChange = this._onPtzInChange.bind(this);
        this._onPtzOutChange = this._onPtzOutChange.bind(this);
        this._onPtzInOutChange = this._onPtzInOutChange.bind(this);
        this._onMessageInOutChange = this._onMessageInOutChange.bind(this);
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const style = {
            ptzInputDivStyle : {
                display: 'grid',
                gridTemplateColumns: '20fr 30fr 50fr 30fr 20fr',
                gridTemplateRows: '30fr 50fr 30fr 50fr',
                gap: '0px',
                padding: '0px'
            },
            ptzButtonStyle : {
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor : 'rgba(0, 0, 0, 0)',
                border : 0,
                padding: '0px'
            },
            ptzCenterStyle : {
                gridRow : 2,
                gridColumn : 3
            },
            ptzUpStyle : {
                gridColumn: 3
            },
            ptzLeftStyle : {
                gridRow : 2,
                gridColumn : 2,
                transform : 'rotate(-90deg)'
            },
            ptzRightStyle : {
                gridRow : 2,
                gridColumn : 4,
                transform : 'rotate(90deg)'
            },
            ptzDownStyle : {
                gridRow : 3,
                gridColumn : 3,
                transform : 'rotate(180deg)'
            },
            ptzZoomInStyle :{
                gridColumn : 5,
                gridRow : 4
            },
            ptzZoomOutStyle : {
                gridColumn : 1,
                gridRow : 4
            },
            ptzSliderStyle : {
                gridColumnStart: 2,
                gridColumnEnd: 5,
                gridRow : 4
            }
        };
        return (
            <div id = 'ptz-input' style={style.ptzInputDivStyle}>
                <button aria-label = {'center'}
                    id = 'PTZ_Center'
                    value="center"
                    appearance='subtle'
                    style={{...style.ptzButtonStyle, ...style.ptzCenterStyle}}
                    onClick = { this._onMessageChange.bind(this,'RESET') }>
                    <Icon size = '50' src = { IconPtzReset } />
                </button>
                <button aria-label = {'UP'}
                    id = 'PTZ_U_Button1'
                    value="UP"
                    appearance='subtle'
                    style={{...style.ptzButtonStyle, ...style.ptzUpStyle}}
                    onMouseDown = { this._onMessageChange.bind(this,'UP_START') }
                    onMouseUp = { this._onMessageChange.bind(this,'UP_END') }>
                    <Icon size = '20' src = { IconPtzArrow } />
                </button>
                <button aria-label = {'LEFT'}
                    id = 'PTZ_L_Button1'
                    value="LEFT"
                    appearance='subtle'
                    style={{...style.ptzButtonStyle, ...style.ptzLeftStyle}}
                    onMouseDown = { this._onMessageChange.bind(this,'LEFT_START') }
                    onMouseUp = { this._onMessageChange.bind(this,'LEFT_END') }>
                    <Icon size = '20' src = { IconPtzArrow } />
                </button>
                <button aria-label = {'RIGHT'}
                    id = 'PTZ_R_Button1'
                    value="RIGHT"
                    appearance='subtle'
                    style={{...style.ptzButtonStyle, ...style.ptzRightStyle}}
                    onMouseDown = { this._onMessageChange.bind(this,'RIGHT_START') }
                    onMouseUp = { this._onMessageChange.bind(this,'RIGHT_END') }>
                    <Icon size = '20' src = { IconPtzArrow } />
                </button>
                <button aria-label = {'DOWN'}
                    id = 'PTZ_D_Button1'
                    value="DOWN"
                    appearance='subtle'
                    style={{...style.ptzButtonStyle, ...style.ptzDownStyle}}
                    onMouseDown = { this._onMessageChange.bind(this,'DOWN_START') }
                    onMouseUp = { this._onMessageChange.bind(this,'DOWN_END') }>
                    <Icon size = '20' src = { IconPtzArrow } />
                </button>
                <button aria-label = {'IN'}
                    id = 'PTZ_I_Button1'
                    value="IN"
                    appearance='subtle'
                    style={{...style.ptzButtonStyle, ...style.ptzZoomInStyle}}
                    onClick = { this._onPtzInChange }>
                    <Icon size = '16' src = { IconPtzPlus } />
                </button>
                <div className = 'ptz_slider_container'
                style={{...style.ptzSliderStyle}}>
                        <input
                            aria-label = "ptz slider"
                            aria-valuemax = { PTZ_SLIDER_SCALE }
                            aria-valuemin = { 0 }
                            aria-valuenow = { this.state.ptzLevel }
                            className = 'ptz__slider'
                            max = { PTZ_SLIDER_SCALE }
                            min = { 0 }
                            step = { 5 }
                            onMouseUp= { this._onPtzInOutChange }
                            onChange = { this._onPtzInOutChange }
                            tabIndex = { 0 }
                            type = 'range'
                            value = { this.state.ptzLevel } />
                </div>
                <button aria-label = {'OUT'}
                    id = 'PTZ_O_Button1'
                    value="OUT"
                    appearance='subtle'
                    style={{...style.ptzButtonStyle, ...style.ptzZoomOutStyle}}
                    onClick = { this._onPtzOutChange }>
                    <Icon size = '16' src = { IconPtzMinus } />
                </button>
            </div>
        );
    }

    _onPtzInChange: (Object) => void;

    /**
     * Sets the internal state of the ptz level for the zoom in.
     *
     * @param {Object} event
     * @private
     * @returns {void}
     */
    _onPtzInChange(event) {
        let ptzLevel = this.state.ptzLevel;
        event.preventDefault();

        if( ptzLevel < 100 ){
            this.setState({ptzLevel : this.state.ptzLevel + 5}, function() {
                this._onMessageInOutChange();
        });}
    }

    _onPtzOutChange: (Object) => void;

    /**
     * Sets the internal state of the ptz level for the zoom out.
     *
     * @param {Object} event
     * @private
     * @returns {void}
     */
    _onPtzOutChange(event) {
        let ptzLevel = this.state.ptzLevel;
        event.preventDefault();

        if( ptzLevel > 0 ){
            this.setState({ptzLevel : this.state.ptzLevel - 5}, function() {
                this._onMessageInOutChange();
        });}
    }

    _onPtzInOutChange: (Object) => void;

    /**
     * Sets the internal state of the ptz level for the ptz slider.
     *
     * @param {Object} event - DOM Event for slider change.
     * @private
     * @returns {void}
     */
    _onPtzInOutChange(event) {
        const ptzLevel = parseInt(event.target.value);

        this.setState({ ptzLevel });
        event.preventDefault();

        if (event.type === 'mouseup') {
            this._onMessageInOutChange();
        }
    }

    _onMessageInOutChange: () => void;

    /**
     * Updates the known message the user is drafting.
     *
     * @private
     * @returns {void}
     */
    _onMessageInOutChange() {
        let zoom = {
            key : 'ZOOM',
            ptzLevel : this.state.ptzLevel
        };

        let json = JSON.stringify(zoom);

        console.log('[PTZ_UI] _onMessageInOutChange Key'  , json);
        this.props.onSend(json);
    }

    _onMessageChange: (Object, Key) => void;

    /**
     * Updates the known message the user is drafting.
     *
     * @param {string} event - Keyboard event.
     * @private
     * @returns {void}
     */
    _onMessageChange(Key, event) {
        if( Key == 'RESET' ){
            this.setState({ ptzLevel : 0 })
        }
        let zoom = {key: Key}

        let json = JSON.stringify(zoom);
        //console.log('[PTZ_UI] _onMessageChange event', event);
        console.log('[PTZ_UI] _onMessageChange Key'  , json);
        event.preventDefault();
        this.props.onSend(json);
    }
}

export default translate(connect()(PtzInput));
