// @flow

import {
    PARTICIPANT_JOINED,
    PARTICIPANT_LEFT, 
    PARTICIPANT_UPDATED, 
    PARTICIPANT_ROLE,
    getParticipantById
} from '../base/participants';
import { ReducerRegistry } from '../base/redux';
import { MEETING_TYPE } from '../video-layout';

import {
    SET_FILMSTRIP_ENABLED,
    SET_FILMSTRIP_VISIBLE,
    SET_MEETING_TYPE,
    SET_HORIZONTAL_VIEW_DIMENSIONS,
    SET_SORT_METHOD,
    SET_TILE_VIEW_DIMENSIONS,
    SET_VERTICAL_VIEW_DIMENSIONS,
    SET_VISIBLE_REMOTE_PARTICIPANTS,
    SET_VOLUME
} from './actionTypes';
import { ORDER_BY } from './constants';
import { sortingRemoteParticipants, setRemoteParticipants } from './functions.any';

const DEFAULT_STATE = {
    /**
     * The indicator which determines whether the {@link Filmstrip} is enabled.
     *
     * @public
     * @type {boolean}
     */
    enabled: true,

    /**
     * The horizontal view dimensions.
     *
     * @public
     * @type {Object}
     */
    horizontalViewDimensions: {},

    meetingType: MEETING_TYPE.NORMAL,

    orderBy: ORDER_BY.NONE,

    /**
     * The custom audio volume levels per participant.
     *
     * @type {Object}
     */
    participantsVolume: {},

    /**
     * The ordered IDs of the remote participants displayed in the filmstrip.
     *
     * NOTE: Currently the order will match the one from the base/participants array. But this is good initial step for
     * reordering the remote participants.
     */
    remoteParticipants: [],

    remoteGuestParticipants: [],

    remoteParticipantsMap: new Map(),

    /**
     * The tile view dimensions.
     *
     * @public
     * @type {Object}
     */
    tileViewDimensions: {},

    /**
     * The vertical view dimensions.
     *
     * @public
     * @type {Object}
     */
    verticalViewDimensions: {},

    /**
     * The indicator which determines whether the {@link Filmstrip} is visible.
     *
     * @public
     * @type {boolean}
     */
    visible: true,

    /**
     * The end index in the remote participants array that is visible in the filmstrip.
     *
     * @public
     * @type {number}
     */
    visibleParticipantsEndIndex: 0,

    /**
     * The visible participants in the filmstrip.
     *
     * @public
     * @type {Array<string>}
     */
    visibleParticipants: [],


    /**
     * The start index in the remote participants array that is visible in the filmstrip.
     *
     * @public
     * @type {number}
     */
    visibleParticipantsStartIndex: 0
};

ReducerRegistry.register(
    'features/filmstrip',
    (state = DEFAULT_STATE, action) => {
        switch (action.type) {
        case SET_FILMSTRIP_ENABLED:
            return {
                ...state,
                enabled: action.enabled
            };

        case SET_FILMSTRIP_VISIBLE:
            return {
                ...state,
                visible: action.visible
            };

        case SET_MEETING_TYPE:
            return {
                ...state,
                meetingType: action.meetingType
            }
        case SET_HORIZONTAL_VIEW_DIMENSIONS:
            return {
                ...state,
                horizontalViewDimensions: action.dimensions
            };
        case SET_TILE_VIEW_DIMENSIONS:
            return {
                ...state,
                tileViewDimensions: action.dimensions
            };
        case SET_VERTICAL_VIEW_DIMENSIONS:
            return {
                ...state,
                verticalViewDimensions: action.dimensions
            };
        case SET_VOLUME:
            return {
                ...state,
                participantsVolume: {
                    ...state.participantsVolume,

                    // NOTE: This would fit better in the features/base/participants. But currently we store
                    // the participants as an array which will make it expensive to search for the volume for
                    // every participant separately.
                    [action.participantId]: action.volume
                }
            };
        case SET_VISIBLE_REMOTE_PARTICIPANTS:
            return {
                ...state,
                visibleParticipantsStartIndex: action.startIndex,
                visibleParticipantsEndIndex: action.endIndex,
                visibleParticipants: state.meetingType === MEETING_TYPE.INSPECTION ? 
                    state.remoteGuestParticipants.slice(action.startIndex, action.endIndex + 1)
                    : state.remoteParticipants.slice(action.startIndex, action.endIndex + 1)
            };
        case PARTICIPANT_JOINED: {
            const { id, local, role, name } = action.participant;

            if (!local) {
                state.remoteParticipantsMap.set(id, {"role": role, "name": name || ''});

                [ state.remoteParticipants, state.remoteGuestParticipants ] = setRemoteParticipants(state);
                const { visibleParticipantsStartIndex: startIndex, visibleParticipantsEndIndex: endIndex } = state;

                if (state.meetingType !== MEETING_TYPE.INSPECTION && state.remoteParticipants.length - 1 <= endIndex) {
                    state.visibleParticipants = state.remoteParticipants.slice(startIndex, endIndex + 1);
                }
                else if (state.meetingType === MEETING_TYPE.INSPECTION && state.remoteGuestParticipants.length - 1 <= endIndex) {
                    state.visibleParticipants = state.remoteGuestParticipants.slice(startIndex, endIndex + 1);
                }
            }

            return state;
        }
        case PARTICIPANT_LEFT: {
            const { id, local } = action.participant;

            if (local) {
                return state;
            }

            state.remoteParticipantsMap.delete(id);

            const reorderedParticipants = new Set(state.remoteParticipants);
            reorderedParticipants.delete(id);
            state.remoteParticipants = Array.from(reorderedParticipants);

            const reorderedGuestParticipants = new Set(state.remoteGuestParticipants);
            reorderedGuestParticipants.delete(id);
            state.remoteGuestParticipants = Array.from(reorderedGuestParticipants);

            const { visibleParticipantsStartIndex: startIndex, visibleParticipantsEndIndex: endIndex } = state;

            if (state.meetingType !== MEETING_TYPE.INSPECTION && state.remoteParticipants.length - 1 <= endIndex) {
                state.visibleParticipants = state.remoteParticipants.slice(startIndex, endIndex + 1);
            }
            else if (state.meetingType === MEETING_TYPE.INSPECTION && state.remoteGuestParticipants.length - 1 <= endIndex) {
                state.visibleParticipants = state.remoteGuestParticipants.slice(startIndex, endIndex + 1);
            }

            delete state.participantsVolume[id];

            return state;
        }
        case PARTICIPANT_UPDATED: {
            const { id, role, name, local } = action.participant;

            if (local || !state.remoteParticipantsMap.has(id) || !(name || role)) {
                return state;
            }

            const remoteParticipant = state.remoteParticipantsMap.get(id);
            const participantRole = remoteParticipant?.role;
            const participantName = remoteParticipant?.name;
            state.remoteParticipantsMap.set(id, {"role" : role||participantRole, "name" : name||participantName});

            if(participantRole === role && participantName === name) {
                return state;
            }

            [ state.remoteParticipants, state.remoteGuestParticipants ] = setRemoteParticipants(state);

            const { visibleParticipantsStartIndex: startIndex, visibleParticipantsEndIndex: endIndex } = state;

            if (state.meetingType !== MEETING_TYPE.INSPECTION && state.remoteParticipants.length - 1 <= endIndex) {
                state.visibleParticipants = state.remoteParticipants.slice(startIndex, endIndex + 1);
            }
            else if (state.meetingType === MEETING_TYPE.INSPECTION && state.remoteGuestParticipants.length - 1 <= endIndex) {
                state.visibleParticipants = state.remoteGuestParticipants.slice(startIndex, endIndex + 1);
            }

            return state;
        }
        case SET_SORT_METHOD: {
            if (!ORDER_BY.hasOwnProperty(action.orderBy)) {
                return state;
            }
            state.orderBy = action.orderBy;

            [ state.remoteParticipants, state.remoteGuestParticipants ] = setRemoteParticipants(state);

            const { visibleParticipantsStartIndex: startIndex, visibleParticipantsEndIndex: endIndex } = state;

            if (state.meetingType !== MEETING_TYPE.INSPECTION && state.remoteParticipants.length - 1 <= endIndex) {
                state.visibleParticipants = state.remoteParticipants.slice(startIndex, endIndex + 1);
            }
            else if (state.meetingType === MEETING_TYPE.INSPECTION && state.remoteGuestParticipants.length - 1 <= endIndex) {
                state.visibleParticipants = state.remoteGuestParticipants.slice(startIndex, endIndex + 1);
            }
        }
        }

        return state;
    });
