// @flow

export const countries = [
    { name: 'Afghanistan',
        dialCode: '93',
        code: 'af' },
    { name: 'Aland Islands',
        dialCode: '358',
        code: 'ax' },
    { name: 'Albania',
        dialCode: '355',
        code: 'al' },
    { name: 'Algeria',
        dialCode: '213',
        code: 'dz' },
    { name: 'AmericanSamoa',
        dialCode: '1684',
        code: 'as' },
    { name: 'Andorra',
        dialCode: '376',
        code: 'ad' },
    { name: 'Angola',
        dialCode: '244',
        code: 'ao' },
    { name: 'Anguilla',
        dialCode: '1264',
        code: 'ai' },
    { name: 'Antarctica',
        dialCode: '672',
        code: 'aq' },
    { name: 'Antigua and Barbuda',
        dialCode: '1268',
        code: 'ag' },
    { name: 'Argentina',
        dialCode: '54',
        code: 'ar' },
    { name: 'Armenia',
        dialCode: '374',
        code: 'am' },
    { name: 'Aruba',
        dialCode: '297',
        code: 'aw' },
    { name: 'Australia',
        dialCode: '61',
        code: 'au' },
    { name: 'Austria',
        dialCode: '43',
        code: 'at' },
    { name: 'Azerbaijan',
        dialCode: '994',
        code: 'az' },
    { name: 'Bahamas',
        dialCode: '1242',
        code: 'bs' },
    { name: 'Bahrain',
        dialCode: '973',
        code: 'bh' },
    { name: 'Bangladesh',
        dialCode: '880',
        code: 'bd' },
    { name: 'Barbados',
        dialCode: '1246',
        code: 'bb' },
    { name: 'Belarus',
        dialCode: '375',
        code: 'by' },
    { name: 'Belgium',
        dialCode: '32',
        code: 'be' },
    { name: 'Belize',
        dialCode: '501',
        code: 'bz' },
    { name: 'Benin',
        dialCode: '229',
        code: 'bj' },
    { name: 'Bermuda',
        dialCode: '1441',
        code: 'bm' },
    { name: 'Bhutan',
        dialCode: '975',
        code: 'bt' },
    { name: 'Bolivia, Plurinational State of',
        dialCode: '591',
        code: 'bo' },
    { name: 'Bosnia and Herzegovina',
        dialCode: '387',
        code: 'ba' },
    { name: 'Botswana',
        dialCode: '267',
        code: 'bw' },
    { name: 'Brazil',
        dialCode: '55',
        code: 'br' },
    { name: 'British Indian Ocean Territory',
        dialCode: '246',
        code: 'io' },
    { name: 'Brunei Darussalam',
        dialCode: '673',
        code: 'bn' },
    { name: 'Bulgaria',
        dialCode: '359',
        code: 'bg' },
    { name: 'Burkina Faso',
        dialCode: '226',
        code: 'bf' },
    { name: 'Burundi',
        dialCode: '257',
        code: 'bi' },
    { name: 'Cambodia',
        dialCode: '855',
        code: 'kh' },
    { name: 'Cameroon',
        dialCode: '237',
        code: 'cm' },
    { name: 'Canada',
        dialCode: '1',
        code: 'ca' },
    { name: 'Cape Verde',
        dialCode: '238',
        code: 'cv' },
    { name: 'Cayman Islands',
        dialCode: ' 345',
        code: 'ky' },
    { name: 'Central African Republic',
        dialCode: '236',
        code: 'cf' },
    { name: 'Chad',
        dialCode: '235',
        code: 'td' },
    { name: 'Chile',
        dialCode: '56',
        code: 'cl' },
    { name: 'China',
        dialCode: '86',
        code: 'cn' },
    { name: 'Christmas Island',
        dialCode: '61',
        code: 'cx' },
    { name: 'Cocos (Keeling) Islands',
        dialCode: '61',
        code: 'cc' },
    { name: 'Colombia',
        dialCode: '57',
        code: 'co' },
    { name: 'Comoros',
        dialCode: '269',
        code: 'km' },
    { name: 'Congo',
        dialCode: '242',
        code: 'cg' },
    {
        name: 'Congo, The Democratic Republic of the Congo',
        dialCode: '243',
        code: 'cd'
    },
    { name: 'Cook Islands',
        dialCode: '682',
        code: 'ck' },
    { name: 'Costa Rica',
        dialCode: '506',
        code: 'cr' },
    { name: 'Cote d\'Ivoire',
        dialCode: '225',
        code: 'ci' },
    { name: 'Croatia',
        dialCode: '385',
        code: 'hr' },
    { name: 'Cuba',
        dialCode: '53',
        code: 'cu' },
    { name: 'Cyprus',
        dialCode: '357',
        code: 'cy' },
    { name: 'Czech Republic',
        dialCode: '420',
        code: 'cz' },
    { name: 'Denmark',
        dialCode: '45',
        code: 'dk' },
    { name: 'Djibouti',
        dialCode: '253',
        code: 'dj' },
    { name: 'Dominica',
        dialCode: '1767',
        code: 'dm' },
    { name: 'Dominican Republic',
        dialCode: '1849',
        code: 'do' },
    { name: 'Ecuador',
        dialCode: '593',
        code: 'ec' },
    { name: 'Egypt',
        dialCode: '20',
        code: 'eg' },
    { name: 'El Salvador',
        dialCode: '503',
        code: 'sv' },
    { name: 'Equatorial Guinea',
        dialCode: '240',
        code: 'gq' },
    { name: 'Eritrea',
        dialCode: '291',
        code: 'er' },
    { name: 'Estonia',
        dialCode: '372',
        code: 'ee' },
    { name: 'Ethiopia',
        dialCode: '251',
        code: 'et' },
    { name: 'Falkland Islands (Malvinas)',
        dialCode: '500',
        code: 'fk' },
    { name: 'Faroe Islands',
        dialCode: '298',
        code: 'fo' },
    { name: 'Fiji',
        dialCode: '679',
        code: 'fj' },
    { name: 'Finland',
        dialCode: '358',
        code: 'fi' },
    { name: 'France',
        dialCode: '33',
        code: 'fr' },
    { name: 'French Guiana',
        dialCode: '594',
        code: 'gf' },
    { name: 'French Polynesia',
        dialCode: '689',
        code: 'pf' },
    { name: 'Gabon',
        dialCode: '241',
        code: 'ga' },
    { name: 'Gambia',
        dialCode: '220',
        code: 'gm' },
    { name: 'Georgia',
        dialCode: '995',
        code: 'ge' },
    { name: 'Germany',
        dialCode: '49',
        code: 'de' },
    { name: 'Ghana',
        dialCode: '233',
        code: 'gh' },
    { name: 'Gibraltar',
        dialCode: '350',
        code: 'gi' },
    { name: 'Greece',
        dialCode: '30',
        code: 'gr' },
    { name: 'Greenland',
        dialCode: '299',
        code: 'gl' },
    { name: 'Grenada',
        dialCode: '1473',
        code: 'gd' },
    { name: 'Guadeloupe',
        dialCode: '590',
        code: 'gp' },
    { name: 'Guam',
        dialCode: '1671',
        code: 'gu' },
    { name: 'Guatemala',
        dialCode: '502',
        code: 'gt' },
    { name: 'Guernsey',
        dialCode: '44',
        code: 'gg' },
    { name: 'Guinea',
        dialCode: '224',
        code: 'gn' },
    { name: 'Guinea-Bissau',
        dialCode: '245',
        code: 'gw' },
    { name: 'Guyana',
        dialCode: '595',
        code: 'gy' },
    { name: 'Haiti',
        dialCode: '509',
        code: 'ht' },
    { name: 'Holy See (Vatican City State)',
        dialCode: '379',
        code: 'va' },
    { name: 'Honduras',
        dialCode: '504',
        code: 'hn' },
    { name: 'Hong Kong',
        dialCode: '852',
        code: 'hk' },
    { name: 'Hungary',
        dialCode: '36',
        code: 'hu' },
    { name: 'Iceland',
        dialCode: '354',
        code: 'is' },
    { name: 'India',
        dialCode: '91',
        code: 'in' },
    { name: 'Indonesia',
        dialCode: '62',
        code: 'id' },
    {
        name: 'Iran, Islamic Republic of Persian Gulf',
        dialCode: '98',
        code: 'ir'
    },
    { name: 'Iraq',
        dialCode: '964',
        code: 'iq' },
    { name: 'Ireland',
        dialCode: '353',
        code: 'ie' },
    { name: 'Isle of Man',
        dialCode: '44',
        code: 'im' },
    { name: 'Israel',
        dialCode: '972',
        code: 'il' },
    { name: 'Italy',
        dialCode: '39',
        code: 'it' },
    { name: 'Jamaica',
        dialCode: '1876',
        code: 'jm' },
    { name: 'Japan',
        dialCode: '81',
        code: 'jp' },
    { name: 'Jersey',
        dialCode: '44',
        code: 'je' },
    { name: 'Jordan',
        dialCode: '962',
        code: 'jo' },
    { name: 'Kazakhstan',
        dialCode: '77',
        code: 'kz' },
    { name: 'Kenya',
        dialCode: '254',
        code: 'ke' },
    { name: 'Kiribati',
        dialCode: '686',
        code: 'ki' },
    {
        name: 'Korea, Democratic People\'s Republic of Korea',
        dialCode: '850',
        code: 'kp'
    },
    { name: 'Korea, Republic of South Korea',
        dialCode: '82',
        code: 'kr' },
    { name: 'Kuwait',
        dialCode: '965',
        code: 'kw' },
    { name: 'Kyrgyzstan',
        dialCode: '996',
        code: 'kg' },
    { name: 'Laos',
        dialCode: '856',
        code: 'la' },
    { name: 'Latvia',
        dialCode: '371',
        code: 'lv' },
    { name: 'Lebanon',
        dialCode: '961',
        code: 'lb' },
    { name: 'Lesotho',
        dialCode: '266',
        code: 'ls' },
    { name: 'Liberia',
        dialCode: '231',
        code: 'lr' },
    { name: 'Libyan Arab Jamahiriya',
        dialCode: '218',
        code: 'ly' },
    { name: 'Liechtenstein',
        dialCode: '423',
        code: 'li' },
    { name: 'Lithuania',
        dialCode: '370',
        code: 'lt' },
    { name: 'Luxembourg',
        dialCode: '352',
        code: 'lu' },
    { name: 'Macao',
        dialCode: '853',
        code: 'mo' },
    { name: 'Macedonia',
        dialCode: '389',
        code: 'mk' },
    { name: 'Madagascar',
        dialCode: '261',
        code: 'mg' },
    { name: 'Malawi',
        dialCode: '265',
        code: 'mw' },
    { name: 'Malaysia',
        dialCode: '60',
        code: 'my' },
    { name: 'Maldives',
        dialCode: '960',
        code: 'mv' },
    { name: 'Mali',
        dialCode: '223',
        code: 'ml' },
    { name: 'Malta',
        dialCode: '356',
        code: 'mt' },
    { name: 'Marshall Islands',
        dialCode: '692',
        code: 'mh' },
    { name: 'Martinique',
        dialCode: '596',
        code: 'mq' },
    { name: 'Mauritania',
        dialCode: '222',
        code: 'mr' },
    { name: 'Mauritius',
        dialCode: '230',
        code: 'mu' },
    { name: 'Mayotte',
        dialCode: '262',
        code: 'yt' },
    { name: 'Mexico',
        dialCode: '52',
        code: 'mx' },
    {
        name: 'Micronesia, Federated States of Micronesia',
        dialCode: '691',
        code: 'fm'
    },
    { name: 'Moldova',
        dialCode: '373',
        code: 'md' },
    { name: 'Monaco',
        dialCode: '377',
        code: 'mc' },
    { name: 'Mongolia',
        dialCode: '976',
        code: 'mn' },
    { name: 'Montenegro',
        dialCode: '382',
        code: 'me' },
    { name: 'Montserrat',
        dialCode: '1664',
        code: 'ms' },
    { name: 'Morocco',
        dialCode: '212',
        code: 'ma' },
    { name: 'Mozambique',
        dialCode: '258',
        code: 'mz' },
    { name: 'Myanmar',
        dialCode: '95',
        code: 'mm' },
    { name: 'Namibia',
        dialCode: '264',
        code: 'na' },
    { name: 'Nauru',
        dialCode: '674',
        code: 'nr' },
    { name: 'Nepal',
        dialCode: '977',
        code: 'np' },
    { name: 'Netherlands',
        dialCode: '31',
        code: 'nl' },
    { name: 'Netherlands Antilles',
        dialCode: '599',
        code: 'an' },
    { name: 'New Caledonia',
        dialCode: '687',
        code: 'nc' },
    { name: 'New Zealand',
        dialCode: '64',
        code: 'nz' },
    { name: 'Nicaragua',
        dialCode: '505',
        code: 'ni' },
    { name: 'Niger',
        dialCode: '227',
        code: 'ne' },
    { name: 'Nigeria',
        dialCode: '234',
        code: 'ng' },
    { name: 'Niue',
        dialCode: '683',
        code: 'nu' },
    { name: 'Norfolk Island',
        dialCode: '672',
        code: 'nf' },
    { name: 'Northern Mariana Islands',
        dialCode: '1670',
        code: 'mp' },
    { name: 'Norway',
        dialCode: '47',
        code: 'no' },
    { name: 'Oman',
        dialCode: '968',
        code: 'om' },
    { name: 'Pakistan',
        dialCode: '92',
        code: 'pk' },
    { name: 'Palau',
        dialCode: '680',
        code: 'pw' },
    { name: 'Palestinian Territory, Occupied',
        dialCode: '970',
        code: 'ps' },
    { name: 'Panama',
        dialCode: '507',
        code: 'pa' },
    { name: 'Papua New Guinea',
        dialCode: '675',
        code: 'pg' },
    { name: 'Paraguay',
        dialCode: '595',
        code: 'py' },
    { name: 'Peru',
        dialCode: '51',
        code: 'pe' },
    { name: 'Philippines',
        dialCode: '63',
        code: 'ph' },
    { name: 'Pitcairn',
        dialCode: '872',
        code: 'pn' },
    { name: 'Poland',
        dialCode: '48',
        code: 'pl' },
    { name: 'Portugal',
        dialCode: '351',
        code: 'pt' },
    { name: 'Puerto Rico',
        dialCode: '1939',
        code: 'pr' },
    { name: 'Qatar',
        dialCode: '974',
        code: 'qa' },
    { name: 'Romania',
        dialCode: '40',
        code: 'ro' },
    { name: 'Russia',
        dialCode: '7',
        code: 'ru' },
    { name: 'Rwanda',
        dialCode: '250',
        code: 'rw' },
    { name: 'Reunion',
        dialCode: '262',
        code: 're' },
    { name: 'Saint Barthelemy',
        dialCode: '590',
        code: 'bl' },
    {
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        dialCode: '290',
        code: 'sh'
    },
    { name: 'Saint Kitts and Nevis',
        dialCode: '1869',
        code: 'kn' },
    { name: 'Saint Lucia',
        dialCode: '1758',
        code: 'lc' },
    { name: 'Saint Martin',
        dialCode: '590',
        code: 'mf' },
    { name: 'Saint Pierre and Miquelon',
        dialCode: '508',
        code: 'pm' },
    { name: 'Saint Vincent and the Grenadines',
        dialCode: '1784',
        code: 'vc' },
    { name: 'Samoa',
        dialCode: '685',
        code: 'ws' },
    { name: 'San Marino',
        dialCode: '378',
        code: 'sm' },
    { name: 'Sao Tome and Principe',
        dialCode: '239',
        code: 'st' },
    { name: 'Saudi Arabia',
        dialCode: '966',
        code: 'sa' },
    { name: 'Senegal',
        dialCode: '221',
        code: 'sn' },
    { name: 'Serbia',
        dialCode: '381',
        code: 'rs' },
    { name: 'Seychelles',
        dialCode: '248',
        code: 'sc' },
    { name: 'Sierra Leone',
        dialCode: '232',
        code: 'sl' },
    { name: 'Singapore',
        dialCode: '65',
        code: 'sg' },
    { name: 'Slovakia',
        dialCode: '421',
        code: 'sk' },
    { name: 'Slovenia',
        dialCode: '386',
        code: 'si' },
    { name: 'Solomon Islands',
        dialCode: '677',
        code: 'sb' },
    { name: 'Somalia',
        dialCode: '252',
        code: 'so' },
    { name: 'South Africa',
        dialCode: '27',
        code: 'za' },
    { name: 'South Sudan',
        dialCode: '211',
        code: 'ss' },
    {
        name: 'South Georgia and the South Sandwich Islands',
        dialCode: '500',
        code: 'gs'
    },
    { name: 'Spain',
        dialCode: '34',
        code: 'es' },
    { name: 'Sri Lanka',
        dialCode: '94',
        code: 'lk' },
    { name: 'Sudan',
        dialCode: '249',
        code: 'sd' },
    { name: 'Suriname',
        dialCode: '597',
        code: 'sr' },
    { name: 'Svalbard and Jan Mayen',
        dialCode: '47',
        code: 'sj' },
    { name: 'Swaziland',
        dialCode: '268',
        code: 'sz' },
    { name: 'Sweden',
        dialCode: '46',
        code: 'se' },
    { name: 'Switzerland',
        dialCode: '41',
        code: 'ch' },
    { name: 'Syrian Arab Republic',
        dialCode: '963',
        code: 'sy' },
    { name: 'Taiwan',
        dialCode: '886',
        code: 'tw' },
    { name: 'Tajikistan',
        dialCode: '992',
        code: 'tj' },
    {
        name: 'Tanzania, United Republic of Tanzania',
        dialCode: '255',
        code: 'tz'
    },
    { name: 'Thailand',
        dialCode: '66',
        code: 'th' },
    { name: 'Timor-Leste',
        dialCode: '670',
        code: 'tl' },
    { name: 'Togo',
        dialCode: '228',
        code: 'tg' },
    { name: 'Tokelau',
        dialCode: '690',
        code: 'tk' },
    { name: 'Tonga',
        dialCode: '676',
        code: 'to' },
    { name: 'Trinidad and Tobago',
        dialCode: '1868',
        code: 'tt' },
    { name: 'Tunisia',
        dialCode: '216',
        code: 'tn' },
    { name: 'Turkey',
        dialCode: '90',
        code: 'tr' },
    { name: 'Turkmenistan',
        dialCode: '993',
        code: 'tm' },
    { name: 'Turks and Caicos Islands',
        dialCode: '1649',
        code: 'tc' },
    { name: 'Tuvalu',
        dialCode: '688',
        code: 'tv' },
    { name: 'Uganda',
        dialCode: '256',
        code: 'ug' },
    { name: 'Ukraine',
        dialCode: '380',
        code: 'ua' },
    { name: 'United Arab Emirates',
        dialCode: '971',
        code: 'ae' },
    { name: 'United Kingdom',
        dialCode: '44',
        code: 'gb' },
    { name: 'United States',
        dialCode: '1',
        code: 'us' },
    { name: 'Uruguay',
        dialCode: '598',
        code: 'uy' },
    { name: 'Uzbekistan',
        dialCode: '998',
        code: 'uz' },
    { name: 'Vanuatu',
        dialCode: '678',
        code: 'vu' },
    {
        name: 'Venezuela, Bolivarian Republic of Venezuela',
        dialCode: '58',
        code: 've'
    },
    { name: 'Vietnam',
        dialCode: '84',
        code: 'vn' },
    { name: 'Virgin Islands, British',
        dialCode: '1284',
        code: 'vg' },
    { name: 'Virgin Islands, U.S.',
        dialCode: '1340',
        code: 'vi' },
    { name: 'Wallis and Futuna',
        dialCode: '681',
        code: 'wf' },
    { name: 'Yemen',
        dialCode: '967',
        code: 'ye' },
    { name: 'Zambia',
        dialCode: '260',
        code: 'zm' },
    { name: 'Zimbabwe',
        dialCode: '263',
        code: 'zw' }
];

const countriesByCodeMap = countries.reduce((result, country) => {
    result[country.dialCode] = country;

    return result;
}, {});

/**
 * Map between country dial codes and country objects.
 *
 */
const codesByNumbersMap = countries.reduce((result, country) => {
    result[country.dialCode] = country.code;

    return result;
}, {});

/**
 * Returns the corresponding country code from a phone number.
 *
 * @param {string} phoneNumber - The phone number.
 * @returns {string}
 */
export function getCountryCodeFromPhone(phoneNumber: string): string {
    const number = phoneNumber.replace(/[+.\s]/g, '');


    for (let i = 4; i > 0; i--) {
        const prefix = number.slice(0, i);

        if (codesByNumbersMap[prefix]) {
            return codesByNumbersMap[prefix];
        }
    }

    return '';
}

/**
 * Returns the corresponding country for a text starting with the dial code.
 *
 * @param {string} text - The text containing the dial code.
 * @returns {Object}
 */
export function getCountryFromDialCodeText(text: string): Object {
    return (
        countriesByCodeMap[text.slice(0, 4)]
        || countriesByCodeMap[text.slice(0, 3)]
        || countriesByCodeMap[text.slice(0, 2)]
        || countriesByCodeMap[text.slice(0, 1)]
        || null
    );
}
