import UIEvents from '../../../service/UI/UIEvents';
import { createToolbarEvent, RECOGINZE_START, sendAnalytics } from '../analytics';
import { openDialog } from '../base/dialog';
import { RecognizeSettingDialog } from './';

import { setRecognized, setTranslated, setTTSEnabled } from '../base/conference';

declare var APP: Object;

export function handleRecognize(dispatch: Function, recognized: boolean) {
    dispatch(setRecognized(recognized));
    dispatch(setTranslated(recognized));
    typeof APP === 'undefined'
        || APP.UI.emitEvent(UIEvents.RECOGINZE_START, recognized);

    if (recognized) {
        dispatch(openDialog(RecognizeSettingDialog));
    }
    else {
        dispatch(setTTSEnabled(recognized));
    }

    sendAnalytics(createToolbarEvent(RECOGINZE_START, {recognized: recognized}));
}

export function handleTTS(dispatch: Function, tts_enabled: boolean) {
    dispatch(setTTSEnabled(tts_enabled));
}

