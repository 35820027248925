import React from 'react';
import { openDialog } from '../../base/dialog';
import { Switch } from '../../base/react';
import { connect } from '../../base/redux';
import { translate } from '../../base/i18n';

import { handleRecognize, handleTTS } from '../'

import { getToolbarButtons } from '../../base/config';

type Props = {
    t: Function,
    dispatch: Function,
    recognized: boolean,
    tts_enabled: boolean,
    isAutoTranslate: boolean
};

/**
 *
 * @returns {React$Element<any>}
 */
function AutoTranslationSwitch({ t, dispatch, recognized, tts_enabled, isAutoTranslate }: Props) {
    if (isAutoTranslate){
        return (
            <>
                <div className = 'flex-container'>
                    <div className = 'flex-item'>
                        {t('autoTranslate.toggleRecognize')}
                    </div>
                    <div className = 'flex-item'>
                        {t('autoTranslate.toggleTts')}
                    </div>
                </div>

                <div className = 'flex-container'>
                    <div className = 'flex-item'>
                        {
                            <Switch
                                id = 'auto-translation-switch'
                                onValueChange = { () => {
                                    handleRecognize(dispatch, !recognized);
                                } }
                                value = { recognized } />
                        }
                    </div>
                    <div className = 'flex-item'>
                        {
                            <Switch
                                id = 'auto-translation-tts-switch'
                                onValueChange = { () => { handleTTS(dispatch, !tts_enabled) } }
                                value = { tts_enabled }
                                disabled = { !recognized } />
                        }
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
}

function mapStateToProps(state) {
    const { recognized, tts_enabled } = state['features/base/conference'];
    const visibleButtons = getToolbarButtons(state);
    const isAutoTranslate = visibleButtons.indexOf('autotranslate') !== -1;

    return {
        recognized: recognized,
        tts_enabled: tts_enabled,
        isAutoTranslate: isAutoTranslate
    };
}

export default translate(connect(mapStateToProps)(AutoTranslationSwitch));


