// @flow
import React, { Component } from 'react';

import { translate } from '../../../base/i18n';
import { Icon, IconClose } from '../../../base/icons';
import { connect } from '../../../base/redux';
import { togglePTZControl, sendCommand } from '../../actions';
import PtzInput from './PtzInput';

type Props = {
    /**
     * True if the ptz window should be rendered.
     */
    _isOpen: boolean,
    _onTogglePtz: Function,
    _onSendMessage: Function,
    dispatch: Dispatch<any>
};

/**
 * React Component for holding the ptz feature in a side panel that slides in
 * and out of view.
 */
class Ptz extends Component<Props> {

    /**
     * Whether or not the {@code ptz} component is off-screen, having finished
     * its hiding animation.
     */
    _isExited: boolean;

    /**
     * Initializes a new {@code ptz} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);

        this._isExited = true;

        // Bind event handlers so they are only bound once for every instance.
        this._renderPtzContent = this._renderPtzContent.bind(this);
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        //console.log('[PTZ_UI] render in ptz.js');
        return (
            <>
                { this._renderPtzContent() }
            </>
        );
    }

    /**
     * Returns a React Element for showing ptz messages and a form to send new
     * Ptz messages.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderPtz() {
        return (
            <>
                <PtzInput
                    onSend = { this.props._onSendMessage } />
            </>
        );
    }

    /**
     * Instantiates a React Element to display at the top of {@code ptz} to
     * close {@code ptz}.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderPtzHeader() {
        return (
            <div className = 'ptz-header'>
                <button
                    className = 'ptz-close'
                    onClick = { this.props._onTogglePtz }>
                    <Icon size = { 16 } src = { IconClose } />
                </button>
            </div>
        );
    }

    _renderPtzContent: () => React$Node | null;

    /**
     * Renders the contents of the ptz panel.
     *
     * @private
     * @returns {ReactElement | null}
     */
    _renderPtzContent() {
        //console.log('[PTZ_UI] _renderPtzContent in ptz.js');
        const { _isOpen } = this.props;

        const ComponentToRender = _isOpen
            ? (
                <>
                    { this._renderPtzHeader() }
                    { this._renderPtz() }
                </>
            )
            : null;


        let className = '';

        if (_isOpen) {
            className = 'slideInExt';
        } else if (this._isExited) {
            className = 'invisible';
        }

        return (
            <div
                className = { `sideToolbarContainerPTZWrapper ${className}` }
                id = 'sideToolbarContainerPTZWrapper'>
                <div
                    id = 'sideToolbarContainerPTZ'>
                    { ComponentToRender }
                </div>
            </div>
        );
    }
}
/**
 * Maps part of redux actions to component's props.
 *
 * @param {Function} dispatch - Redux's {@code dispatch} function.
 * @private
 * @returns {Object}
 */
function _mapDispatchToProps(dispatch: Function): Object {
    return {
        /**
         * Toggles the ptz.
         *
         * @returns {Function}
         */
        _onTogglePtz() {
            dispatch(togglePTZControl());
        },
        
        /**
         * Sends a text message.
         *
         * @private
         * @param {string} text - The text message to be sent.
         * @returns {void}
         * @type {Function}
         */
        _onSendMessage(text: string) {
            //console.log('[PTZ_UI] _onSendMessage : ', text);
            dispatch(sendCommand(text));
        }
    };
}
/**
 * Maps (parts of) the redux state to {@link ptz} React {@code Component}
 * props.
 *
 * @param {Object} state - The redux store/state.
 * @private
 * @returns {{
 *     _isOpen: boolean
 * }}
 */
function _mapStateToProps(state: Object) {
    const { isOpen } = state['features/ptz'];

    return {
        _isOpen: isOpen
    };
}

export default translate(connect(_mapStateToProps, _mapDispatchToProps)(Ptz));
