// @flow

import React from 'react';

import { connect } from '../../base/redux';

/**
 * The type of the React {@code Component} props of {@link ZoomButton}.
 */
type Props = {
    _noAutoPlayVideo: boolean,
    id: string,
    muted: boolean,
    playsInline: Boolean,
    scale: number,
    translateX: number,
    translateY: number,
    dispatch: Function,
};

/**
 * Implementation of zoom video.
 */
/**
 *
 * @returns {React$Element<any>}
 */
function ZoomVideo({ _noAutoPlayVideo, id, muted, playsInline, scale, translateX, translateY }: Props) {
    const offsetX = ((scale * (50 - translateX)) / 100) - 50;
    const offsetY = ((scale * (50 - translateY)) / 100) - 50;

    return (
        <div
            style = {{ position: 'absolute',
                width: '100%',
                height: '100%',
                right: `${offsetX}%`,
                bottom: `${offsetY}%` }}>
            <video
                autoPlay = { !_noAutoPlayVideo }
                id = { id }
                muted = { muted }
                playsInline = { playsInline }
                style = {{ width: `${scale}%`, height: `${scale}%` }} />
        </div>
    );
}

function mapStateToProps(state) {
    const { scale, translateX, translateY } = state['features/zoom'];

    return {
        scale,
        translateX,
        translateY
    };
}

export default connect(mapStateToProps)(ZoomVideo);

