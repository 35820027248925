/**
 * The URL for Google Privacy Policy.
 */
export const GOOGLE_PRIVACY_POLICY = 'https://policies.google.com/privacy';

/**
 * The URL that is the main landing page for YouTube live streaming and should
 * have a user's live stream key.
 */
export const YOUTUBE_LIVE_DASHBOARD_URL = 'https://www.youtube.com/live_dashboard';

/**
 * The URL for YouTube terms and conditions.
 */
export const YOUTUBE_TERMS_URL = 'https://www.youtube.com/t/terms';
