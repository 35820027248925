// @flow

import { IconZoomMinus } from '../../base/icons';
import { connect } from '../../base/redux';
import { AbstractButton, type AbstractButtonProps } from '../../base/toolbox/components';
import { processZoomOut } from '../actions';

import { isRemoteTrackMuted } from '../../base/tracks/functions';
import { MEDIA_TYPE } from '../../base/media';

/**
 * The type of the React {@code Component} props of {@link ZoomOutButton}.
 */
type Props = AbstractButtonProps & {
    dispatch : Function,
    scale : number,
    isVideoMuted : Boolean
};

/**
 * Implementation of a button for zoom in.
 */
class ZoomOutButton extends AbstractButton<Props, *> {
    icon = IconZoomMinus;

    /**
     * Helper function to be implemented by subclasses, which should be used
     * to handle the button being clicked / pressed.
     *
     * @protected
     * @returns {void}
     */
    _handleClick() {
        this.props.dispatch(processZoomOut());
    }

    /**
     * Helper function to be implemented by subclasses, which must return a
     * boolean value indicating if this button is disabled or not.
     *
     * @protected
     * @returns {boolean}
     */
     _isDisabled() {
        if( this.props.scale == 100 || this.props.isVideoMuted ) {
            return true;
        }
        return false;
     }
}

/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @returns {Props}
 */
function mapStateToProps(state) {
    const { scale } = state['features/zoom'];
    const largeVideoID  = state['features/large-video'].participantId;
    let tracks = state['features/base/tracks'];
    let isVideoMuted = isRemoteTrackMuted(tracks, MEDIA_TYPE.VIDEO, largeVideoID);

    return {
        scale,
        isVideoMuted
    };
}

export default connect(mapStateToProps)(ZoomOutButton);

