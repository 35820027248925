import ReducerRegistry from '../base/redux/ReducerRegistry';
import { set } from '../base/redux/functions';

import {
    PROCESS_TRANSLATE_X,
    PROCESS_TRANSLATE_Y,
    PROCESS_ZOOM_IN,
    PROCESS_ZOOM_OUT,
    VISIBLE_ZOOM_IN_OUT
} from './actionTypes';

/**
 * The default state of the auto-translate feature.
 *
 * @type {Object}
 */
const DEFAULT_STATE = {
    visibleZoomInOut: false,
    scale: 100,
    translateX: 0,
    translateY: 0
};

const STORE_NAME = 'features/zoom';

ReducerRegistry.register(STORE_NAME, (state = DEFAULT_STATE, action) => {
    switch (action.type) {
    case VISIBLE_ZOOM_IN_OUT:
        return set(
            state,
            'visibleZoomInOut',
            action.visibleZoomInOut
        );
    case PROCESS_ZOOM_IN:
    case PROCESS_ZOOM_OUT:
        return set(
            state,
            'scale',
            action.scale);

    case PROCESS_TRANSLATE_X:
        return set(
            state,
            'translateX',
            action.translateX);

    case PROCESS_TRANSLATE_Y:
        return set(
            state,
            'translateY',
            action.translateY);
    }
    return state;
});
