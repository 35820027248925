import { toState } from '../base/redux';

import { ORDER_BY } from './constants';

import { PARTICIPANT_ROLE } from '../base/participants';

export function setRemoteParticipants(state: Object) {
    const reorderedParticipants = Array.from(state.remoteParticipantsMap);
    const sortingMethod = (a, b) => {
        if (state.orderBy === ORDER_BY.DISPLAY_NAME_ASC) {
            return a[1].name.localeCompare(b[1].name);
        } else if (state.orderBy === ORDER_BY.DISPLAY_NAME_DESC) {
            return -a[1].name.localeCompare(b[1].name);
        }
        return 1;
    }

    reorderedParticipants.sort(sortingMethod);

    const sortedRemoteParticipants = new Map(reorderedParticipants);
    const sortedRemoteParticipantsId = Array.from(sortedRemoteParticipants.keys());

    const remoteParticipants = [ ...sortedRemoteParticipantsId ];
    const remoteGuestParticipants = sortedRemoteParticipantsId.filter(id => {
        if ( state.remoteParticipantsMap.get(id).role === PARTICIPANT_ROLE.PARTICIPANT ) {
            return true;
        }
        return false;
    });

    return [ remoteParticipants, remoteGuestParticipants ];
}
