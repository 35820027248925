// @flow

import InlineDialog from '@atlaskit/inline-dialog';
import React from 'react';

import { translate } from '../../base/i18n';
import { IconZoomMenu } from '../../base/icons';
import { connect } from '../../base/redux';
import { AbstractButton, type AbstractButtonProps } from '../../base/toolbox/components';
import { setVisibleZoomInOut } from '../actions';

import ZoomInButton from './ZoomInButton';
import ZoomOutButton from './ZoomOutButton';

/**
 * The type of the React {@code Component} props of {@link ZoomButton}.
 */
type Props = AbstractButtonProps & {
    dispatch : Function,
    visibleZoomInOut: boolean,
};

/**
 * Implementation of a button for zoom.
 */
class ZoomButton extends AbstractButton<Props, *> {
    accessibilityLabel = 'toolbar.accessibilityLabel.zoom';
    icon = IconZoomMenu;
    label = 'toolbar.zoom';
    tooltip = 'toolbar.zoom';

    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props.visibleZoomInOut;
    }

    /**
     * Helper function to be implemented by subclasses, which should be used
     * to handle the button being clicked / pressed.
     *
     * @protected
     * @returns {void}
     */
    _handleClick() {
        this.props.dispatch(setVisibleZoomInOut(!this.props.visibleZoomInOut));
    }

    _onCloseZoomInOut = () => {
        this.props.dispatch(setVisibleZoomInOut(false));
    }

    /**
     * Overrides AbstractButton's {@link Component#render()}.
     *
     * @override
     * @protected
     * @returns {boReact$Nodeolean}
     */
    render(): React$Node {
        return (
            <div className = 'zoom-container'>
                <InlineDialog
                    content = {
                        <div>
                            <ZoomInButton customClass = 'zoom-button' />
                            <ZoomOutButton customClass = 'zoom-button' />
                        </div>
                    }
                    isOpen = { this.props.visibleZoomInOut }
                    onClose = { this._onCloseZoomInOut }
                    position = 'top center'>
                    { super.render() }
                </InlineDialog>
            </div>
        );
    }
}

/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @returns {Props}
 */
function mapStateToProps(state) {
    const { visibleZoomInOut } = state['features/zoom'];

    return {
        visibleZoomInOut
    };
}

export default translate(connect(mapStateToProps)(ZoomButton));

