/* @flow */
import UIEvents from '../../../../service/UI/UIEvents';
import React, { Component } from 'react';

import { Dialog, hideDialog } from '../../base/dialog';
import { translate } from '../../base/i18n';
import { connect } from '../../base/redux';
import { updateSettings } from '../../base/settings';
import autoTranslation from '../autoTranslation.json';

import DropdownMenu, {
    DropdownItem,
    DropdownItemGroup
} from '@atlaskit/dropdown-menu';

/**
 * The type of the React {@code Component} props of
 * {@link RecognizeSettingDialog}.
 */
type Props = {
    recognizeLanguage: string,
    translateLanguage: string,
    languages: Array<string>,
    dispatch : Function,
    t: Function
};

/**
 * The type of the React {@code Component} state of
 * {@link RecognizeSettingDialog}.
 */
type State = {

    /**
     * Whether or not the language select dropdown is open.
     */
    isRecognizeLanguageSelectOpen: boolean,
    isTranslateLanguageSelectOpen: boolean,
    currentRecognizeLanguage: string,
    currentTranslateLanguage: string
};

/**
 * Implements a React {@link Component} which displays a dialog describing
 * Translate Settings.
 *
 * @extends Component
 */
class RecognizeSettingDialog extends Component<Props, State> {
    /**
     * Initializes a new {@code MoreTab} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);

        this.state = {
            isRecognizeLanguageSelectOpen: false,
            isTranslateLanguageSelectOpen: false,
            currentRecognizeLanguage: this.props.recognizeLanguage,
            currentTranslateLanguage: this.props.translateLanguage
        };

        // Bind event handler so it is only bound once for every instance.
        this._onSubmit
            = this._onSubmit.bind(this);
        this._onRecognizeChange
            = this._onRecognizeChange.bind(this);
        this._onTranslateChange
            = this._onTranslateChange.bind(this);
        this._onRecognizeLanguageDropdownOpenChange
            = this._onRecognizeLanguageDropdownOpenChange.bind(this);
        this._onTranslateLanguageDropdownOpenChange
            = this._onTranslateLanguageDropdownOpenChange.bind(this);
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const {
            isRecognizeLanguageSelectOpen,
            isTranslateLanguageSelectOpen,
            currentRecognizeLanguage,
            currentTranslateLanguage
        } = this.state;

        const { t } = this.props;

        const recognizeLanguageItems
            = this.props.languages.map(language => (
                <DropdownItem
                    key = { language }

                    // eslint-disable-next-line react/jsx-no-bind
                    onClick = {
                        () => this._onRecognizeChange(language) }>
                    { language }
                </DropdownItem>));

        const translateLanguageItems
            = this.props.languages.map(language => (
                <DropdownItem
                    key = { language }

                    // eslint-disable-next-line react/jsx-no-bind
                    onClick = {
                        () => this._onTranslateChange(language) }>
                    { language }
                </DropdownItem>));

        return (
            <Dialog
		titleKey = 'autoTranslate.title'
                onSubmit = { this._onSubmit }>
                <div className = 'auto-translate'>
                <div className = 'auto-translate-container'>
                    <div className = 'flex-container-text'>
                        <div className = 'flex-text'>
                            { t('autoTranslate.recognize') }
                        </div>
                        <div className = 'flex-text'>
                            { t('autoTranslate.translate') }
                        </div>
                    </div>

                    <div className = 'flex-container-item'>
                        <div className = 'flex-item'>
                            <div className = 'dropdown-menu'>
                                <DropdownMenu
                                    isOpen = { isRecognizeLanguageSelectOpen }
                                    onOpenChange = { this._onRecognizeLanguageDropdownOpenChange }
                                    shouldFitContainer = { true }
                                    trigger = { currentRecognizeLanguage
                                        ? currentRecognizeLanguage
                                        : '' }
                                    triggerButtonProps = {{
                                        appearance: 'primary',
                                        shouldFitContainer: true
                                    }}
                                    triggerType = 'button'>
                                    <DropdownItemGroup>
                                        { recognizeLanguageItems }
                                    </DropdownItemGroup>
                                </DropdownMenu>
                            </div>
                        </div>
                        <div className = 'flex-item'>
                            <div className = 'dropdown-menu'>
                                <DropdownMenu
                                    isOpen = { isTranslateLanguageSelectOpen }
                                    onOpenChange = { this._onTranslateLanguageDropdownOpenChange }
                                    shouldFitContainer = { true }
                                    trigger = { currentTranslateLanguage
                                        ? currentTranslateLanguage
                                        : '' }
                                    triggerButtonProps = {{
                                        appearance: 'primary',
                                        shouldFitContainer: true
                                    }}
                                    triggerType = 'button'>
                                    <DropdownItemGroup>
                                        { translateLanguageItems }
                                    </DropdownItemGroup>
                                </DropdownMenu>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className = 'flex-help'>
                        { t('autoTranslate.help') }
                    </div>
                </div>
            </Dialog>
        );
    }

    _onRecognizeLanguageDropdownOpenChange: (Object) => void;

    /**
     * Callback invoked to toggle display of the language select dropdown.
     *
     * @param {Object} event - The event for opening or closing the dropdown.
     * @private
     * @returns {void}
     */
    _onRecognizeLanguageDropdownOpenChange({ isOpen }) {
        this.setState({isRecognizeLanguageSelectOpen: isOpen });
    }

    _onTranslateLanguageDropdownOpenChange: (Object) => void;

    /**
     * Callback invoked to toggle display of the language select dropdown.
     *
     * @param {Object} event - The event for opening or closing the dropdown.
     * @private
     * @returns {void}
     */
    _onTranslateLanguageDropdownOpenChange({ isOpen }) {
        this.setState({isTranslateLanguageSelectOpen: isOpen });
    }

    _onRecognizeChange: (string) => void;
    _onRecognizeChange(language) {
        this.setState({currentRecognizeLanguage: language});
    }

    _onTranslateChange: (string) => void;
    _onTranslateChange(language) {
        this.setState({currentTranslateLanguage: language});
    }

    _onSubmit: () => void;
    _onSubmit() {
        this.props.dispatch(updateSettings(
            {
                recognizeLanguage: this.state.currentRecognizeLanguage,
                translateLanguage: this.state.currentTranslateLanguage
            }));

		typeof APP === 'undefined'
            || APP.UI.emitEvent(UIEvents.RECOGINZE_CHANGE_LANG);

        this.props.dispatch(hideDialog());
    }
}

function _mapStateToProps(state: Object) {
    const {recognizeLanguage, translateLanguage} = state['features/base/settings'];

    return {
        recognizeLanguage: recognizeLanguage,
        translateLanguage: translateLanguage,
        languages : Object.keys(autoTranslation)
    };
}

export default translate(connect(_mapStateToProps)(RecognizeSettingDialog));

