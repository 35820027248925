// @flow
import { jitsiLocalStorage } from '@jitsi/js-utils/jitsi-local-storage';

import UIEvents from '../../../../service/UI/UIEvents';
import {
    ACTION_SHORTCUT_TRIGGERED,
    VIDEO_MUTE,
    createShortcutEvent,
    createToolbarEvent,
    sendAnalytics
} from '../../analytics';
import { setAudioOnly } from '../../base/audio-only';
import { getFeatureFlag, VIDEO_MUTE_BUTTON_ENABLED } from '../../base/flags';
import { translate } from '../../base/i18n';
import {
    VIDEO_MUTISM_AUTHORITY,
    setVideoMuted
} from '../../base/media';
import { connect } from '../../base/redux';
import { AbstractBackgroundHideButton } from '../../base/toolbox/components';
import type { AbstractButtonProps } from '../../base/toolbox/components';
import { getLocalVideoTrack, getLocalVideoType, isLocalCameraTrackMuted } from '../../base/tracks';
import { isVideoMuteButtonDisabled, isBackgroundSettingsButtonDisabled } from '../functions';
import { toggleBackgroundEffect } from '../../virtual-background';
import { VIRTUAL_BACKGROUND_TYPE } from '../../virtual-background/constants';

declare var APP: Object;

const nonBackground = {
    enabled: false,
    selectedThumbnail: 'none'
}

const backgroundBlur = {
    backgroundType: VIRTUAL_BACKGROUND_TYPE.BLUR,
    enabled: true,
    blurValue: 25,
    selectedThumbnail: 'blur'
}

/**
 * The type of the React {@code Component} props of {@link BackgroundHideButton}.
 */
type Props = AbstractButtonProps & {

    /**
     * Whether video button is disabled or not.
     */
    _videoDisabled: boolean,

    /**
     * The redux {@code dispatch} function.
     */
    dispatch: Function
}

/**
 * Component that renders a toolbar button for toggling video mute.
 *
 * @extends AbstractBackgroundHideButton
 */
class BackgroundHideButton extends AbstractBackgroundHideButton<Props, *> {
    accessibilityLabel = 'toolbar.accessibilityLabel.backgroundhide';
    label = 'toolbar.backgroundhide';
    tooltip = 'toolbar.backgroundhide';

    /**
     * Initializes a new {@code BackgroundHideButton} instance.
     *
     * @param {Props} props - The read-only React {@code Component} props with
     * which the new instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);
    }

    /**
     * Indicates if video is currently disabled or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isDisabled() {
        return this.props._isDisabled;
    }

    /**
     * Indicates if video is currently muted or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
     _isBackgroundHidden() {
        return this.props._backgroundEffectEnabled;
    }

    _setBackgroundHidden(effectMuted) {
        let options = effectMuted ? this.props._backgroundEffect : nonBackground;
        this.props.dispatch(toggleBackgroundEffect(options, this.props._localTrack));

        let updatedStoredOptions = JSON.parse(jitsiLocalStorage.getItem('storedVirtualBackground')) || {};

        if (!effectMuted) {
            updatedStoredOptions[this.props.property_code].backgroundEffectEnabled = false;
        } else {
            updatedStoredOptions[this.props.property_code] = {
                backgroundEffectEnabled: options.enabled,
                blurValue: options.blurValue,
                backgroundType: options.backgroundType,
                selectedThumbnail: options.selectedThumbnail
            }
        }
        jitsiLocalStorage.setItem('storedVirtualBackground', JSON.stringify(updatedStoredOptions));
    }
}

/**
 * Maps (parts of) the redux state to the associated props for the
 * {@code HideBackgroundButton} component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 *     _audioOnly: boolean,
 *     _videoMuted: boolean
 * }}
 */
function _mapStateToProps(state): Object {
    const { enabled: audioOnly } = state['features/base/audio-only'];
    const tracks = state['features/base/tracks'];
    const { backgroundEffectEnabled, backgroundEffect } = state['features/virtual-background'];
    const enabledFlag = getFeatureFlag(state, VIDEO_MUTE_BUTTON_ENABLED, true);
    let effect;

    if (!backgroundEffect || !backgroundEffect.backgroundType) {
        effect = {
            ...backgroundBlur
        }
    } else {
        effect = {
            ...backgroundEffect,
            enabled: true
        }
    }

    return {
        _videoDisabled: isVideoMuteButtonDisabled(state),
        _isDisabled: isBackgroundSettingsButtonDisabled(state),
        _localTrack: getLocalVideoTrack(tracks)?.jitsiTrack,
        _backgroundEffectEnabled: backgroundEffectEnabled,
        _backgroundEffect: effect,
        property_code: state['features/base/config'].prospatium?.property_code.toLowerCase(),
        visible: enabledFlag,
    };
}

export default translate(connect(_mapStateToProps)(BackgroundHideButton));
