// @flow

import { batch } from 'react-redux';

import { ENDPOINT_REACTION_NAME } from '../../../modules/API/constants';
import { APP_WILL_MOUNT, APP_WILL_UNMOUNT } from '../base/app';
import {
    CONFERENCE_JOINED,
    getCurrentConference
} from '../base/conference';
import { openDialog } from '../base/dialog';
import {
    JitsiConferenceErrors,
    JitsiConferenceEvents
} from '../base/lib-jitsi-meet';
import { setActiveModalId } from '../base/modal';
import {
    getLocalParticipant,
    getParticipantById,
    getParticipantDisplayName
} from '../base/participants';
import { MiddlewareRegistry, StateListenerRegistry } from '../base/redux';
import { playSound, registerSound, unregisterSound } from '../base/sounds';
import { openDisplayNamePrompt } from '../display-name';
import { ADD_REACTION_MESSAGE } from '../reactions/actionTypes';
import { pushReactions } from '../reactions/actions.any';
import { getReactionMessageFromBuffer } from '../reactions/functions.any';
import { endpointMessageReceived } from '../subtitles';
import { showToolbox } from '../toolbox/actions';
import {
    hideToolbox,
    setToolboxTimeout,
    setToolboxVisible
} from '../toolbox/actions.web';

import { ADD_MESSAGE, SEND_MESSAGE, OPEN_CHAT, CLOSE_CHAT } from './actionTypes';
import { addMessage, clearMessages } from './actions';
import { closeChat } from './actions.any';
import { ChatPrivacyDialog } from './components';
import {
    CHAT_VIEW_MODAL_ID,
    INCOMING_MSG_SOUND_ID,
    MESSAGE_TYPE_ERROR,
    MESSAGE_TYPE_LOCAL,
    MESSAGE_TYPE_REMOTE,
    // Prospatium -----
    MESSAGE_TYPE_RECOGNIZE,
    MESSAGE_TYPE_TRANSLATE
    // Prospatium -----
} from './constants';
import { getUnreadCount } from './functions';
import { INCOMING_MSG_SOUND_FILE } from './sounds';
// Prospatium -----
import logger from '../analytics/logger';
import autoTranslation from '../auto-translate/autoTranslation.json';
// Prospatium -----

declare var APP: Object;
declare var interfaceConfig : Object;

/**
 * Timeout for when to show the privacy notice after a private message was received.
 *
 * E.g. if this value is 20 secs (20000ms), then we show the privacy notice when sending a non private
 * message after we have received a private message in the last 20 seconds.
 */
const PRIVACY_NOTICE_TIMEOUT = 20 * 1000;

/**
 * Implements the middleware of the chat feature.
 *
 * @param {Store} store - The redux store.
 * @returns {Function}
 */
MiddlewareRegistry.register(store => next => action => {
    const { dispatch, getState } = store;
    const localParticipant = getLocalParticipant(getState());
    let isOpen, unreadCount;

    switch (action.type) {
    case ADD_MESSAGE:
        unreadCount = action.hasRead ? 0 : getUnreadCount(getState()) + 1;
        isOpen = getState()['features/chat'].isOpen;

        if (typeof APP !== 'undefined') {
            APP.API.notifyChatUpdated(unreadCount, isOpen);
        }
        break;

    case APP_WILL_MOUNT:
        dispatch(
                registerSound(INCOMING_MSG_SOUND_ID, INCOMING_MSG_SOUND_FILE));
        break;

    case APP_WILL_UNMOUNT:
        dispatch(unregisterSound(INCOMING_MSG_SOUND_ID));
        break;

    case CONFERENCE_JOINED:
        _addChatMsgListener(action.conference, store);
        break;

    case OPEN_CHAT:
        if (navigator.product === 'ReactNative') {
            if (localParticipant.name) {
                dispatch(setActiveModalId(CHAT_VIEW_MODAL_ID));
            } else {
                dispatch(openDisplayNamePrompt(() => {
                    dispatch(setActiveModalId(CHAT_VIEW_MODAL_ID));
                }));
            }
        } else {
            dispatch(setActiveModalId(CHAT_VIEW_MODAL_ID));
        }

        unreadCount = 0;

        if (typeof APP !== 'undefined') {
            APP.API.notifyChatUpdated(unreadCount, true);
        }
        break;

    case CLOSE_CHAT:
        unreadCount = 0;

        if (typeof APP !== 'undefined') {
            APP.API.notifyChatUpdated(unreadCount, false);
        }

        dispatch(setActiveModalId());
        break;

    case SEND_MESSAGE: {
        const state = store.getState();
        const { conference } = state['features/base/conference'];

        if (conference) {
            // There may be cases when we intend to send a private message but we forget to set the
            // recipient. This logic tries to mitigate this risk.
            const shouldSendPrivateMessageTo = _shouldSendPrivateMessageTo(state, action);

            if (shouldSendPrivateMessageTo) {
                dispatch(openDialog(ChatPrivacyDialog, {
                    message: action.message,
                    participantID: shouldSendPrivateMessageTo
                }));
            } else {
                // Sending the message if privacy notice doesn't need to be shown.

                const { privateMessageRecipient } = state['features/chat'];

                if (typeof APP !== 'undefined') {
                    APP.API.notifySendingChatMessage(action.message, Boolean(privateMessageRecipient));
                }

                if (privateMessageRecipient) {
                    conference.sendPrivateTextMessage(privateMessageRecipient.id, action.message);
                    _persistSentPrivateMessage(store, privateMessageRecipient.id, action.message);
                } else {
                    conference.sendTextMessage(action.message);
                }
            }
        }
        break;
    }

    case ADD_REACTION_MESSAGE: {
        _handleReceivedMessage(store, {
            id: localParticipant.id,
            message: action.message,
            privateMessage: false,
            timestamp: Date.now()
        }, false);
    }
    }

    return next(action);
});

/**
 * Set up state change listener to perform maintenance tasks when the conference
 * is left or failed, e.g. clear messages or close the chat modal if it's left
 * open.
 */
StateListenerRegistry.register(
    state => getCurrentConference(state),
    (conference, { dispatch, getState }, previousConference) => {
        if (conference !== previousConference) {
            // conference changed, left or failed...

            if (getState()['features/chat'].isOpen) {
                // Closes the chat if it's left open.
                dispatch(closeChat());
            }

            // Clear chat messages.
            dispatch(clearMessages());
        }
    });

StateListenerRegistry.register(
    state => state['features/chat'].isOpen,
    (isOpen, { dispatch }) => {
        if (typeof APP !== 'undefined' && isOpen) {
            dispatch(showToolbox());
        }
    }
);

/**
 * Registers listener for {@link JitsiConferenceEvents.MESSAGE_RECEIVED} that
 * will perform various chat related activities.
 *
 * @param {JitsiConference} conference - The conference instance on which the
 * new event listener will be registered.
 * @param {Object} store - The redux store object.
 * @private
 * @returns {void}
 */
function _addChatMsgListener(conference, store) {
    if (store.getState()['features/base/config'].iAmRecorder) {
        // We don't register anything on web if we are in iAmRecorder mode
        return;
    }

    conference.on(
        JitsiConferenceEvents.MESSAGE_RECEIVED,
        (id, message, timestamp) => {
            _handleReceivedMessage(store, {
                id,
                message,
                privateMessage: false,
                timestamp
            });
        }
    );

    conference.on(
        JitsiConferenceEvents.PRIVATE_MESSAGE_RECEIVED,
        (id, message, timestamp) => {
            _handleReceivedMessage(store, {
                id,
                message,
                privateMessage: true,
                timestamp
            });
        }
    );

    conference.on(
        JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED,
        (...args) => {
            store.dispatch(endpointMessageReceived(...args));

            if (args && args.length >= 2) {
                const [ { _id }, eventData ] = args;

                if (eventData.name === ENDPOINT_REACTION_NAME) {
                    batch(() => {
                        store.dispatch(setToolboxVisible(true));
                        store.dispatch(setToolboxTimeout(
                                () => store.dispatch(hideToolbox()),
                                5000)
                        );
                        store.dispatch(pushReactions(eventData.reactions));
                    });

                    _handleReceivedMessage(store, {
                        id: _id,
                        message: getReactionMessageFromBuffer(eventData.reactions),
                        privateMessage: false,
                        timestamp: eventData.timestamp
                    }, false);
                }
            }
        });

    conference.on(
        JitsiConferenceEvents.CONFERENCE_ERROR, (errorType, error) => {
            errorType === JitsiConferenceErrors.CHAT_ERROR && _handleChatError(store, error);
        });
}

/**
 * Handles a chat error received from the xmpp server.
 *
 * @param {Store} store - The Redux store.
 * @param  {string} error - The error message.
 * @returns {void}
 */
function _handleChatError({ dispatch }, error) {
    dispatch(addMessage({
        hasRead: true,
        messageType: MESSAGE_TYPE_ERROR,
        message: error,
        privateMessage: false,
        timestamp: Date.now()
    }));
}

/**
 * Function to handle an incoming chat message.
 *
 * @param {Store} store - The Redux store.
 * @param {Object} message - The message object.
 * @param {boolean} shouldPlaySound - Whether or not to play the incoming message sound.
 * @returns {void}
 */
function _handleReceivedMessage({ dispatch, getState },
        { id, message, privateMessage, timestamp },
        shouldPlaySound = true
) {
    // Logic for all platforms:
    const state = getState();
    const { isOpen: isChatOpen } = state['features/chat'];
    const { disableIncomingMessageSound } = state['features/base/config'];
    const { soundsIncomingMessage: soundEnabled } = state['features/base/settings'];

    if (!disableIncomingMessageSound && soundEnabled && shouldPlaySound && !isChatOpen) {
        dispatch(playSound(INCOMING_MSG_SOUND_ID));
    }

    // Provide a default for for the case when a message is being
    // backfilled for a participant that has left the conference.
    const participant = getParticipantById(state, id) || {};
    const localParticipant = getLocalParticipant(getState);
    const displayName = getParticipantDisplayName(state, id);
    const hasRead = participant.local || isChatOpen;
    const timestampToDate = timestamp ? new Date(timestamp) : new Date();
    const millisecondsTimestamp = timestampToDate.getTime();

    // Prospatium -----
    const { translated } = state['features/base/conference'];

    let otherType;

    try {
        let data = JSON.parse(message);
        if (typeof data === 'object' && data['type'] && data.type === 'translate')
        {
            logger.log(data);
            message = data.message;
            if (!participant.local)
            { //remote
                if (translated) {
                    otherType = MESSAGE_TYPE_TRANSLATE;

                    // auto-transate
                    const { translateLanguage } = state['features/base/settings'];
                    let to = autoTranslation.Korean.to_language;
                    var json = autoTranslation;
                    for(var key in json) {
                        if (json[key].language === translateLanguage) {
                        to = json[key].to_language;
                        break;
                        }
                    }

                    if (data.from === to) {
                        speak(state, to, message);
                        message = '[T] : ' + message + ' (' + data.confidence + ')';
                    } else {
                        translateAsync(message, data.from, to, data.confidence, dispatch, state, JSON.stringify({
                            'displayName': displayName,
                            'hasRead': hasRead,
                            'id': id,
                            'messageType': participant.local ? MESSAGE_TYPE_LOCAL : MESSAGE_TYPE_REMOTE,
                            'otherType' : otherType,
                            'message': message,
                            'privateMessage': privateMessage,
                            'recipient': getParticipantDisplayName(state, localParticipant.id),
                            'timestamp': millisecondsTimestamp
                        }));
                        return;
                    }
                }
            }
            else {
                message = '[S] : ' + data.message + ' (' + data.confidence + ')';
                otherType = MESSAGE_TYPE_RECOGNIZE;
            }
        }
    } catch (error) {
       // logger.log(error);
    }
    // Prospatium -----

    dispatch(addMessage({
        displayName,
        hasRead,
        id,
        messageType: participant.local ? MESSAGE_TYPE_LOCAL : MESSAGE_TYPE_REMOTE,
        // Prospatium -----
        otherType: otherType,
        // Prospatium -----
        message,
        privateMessage,
        recipient: getParticipantDisplayName(state, localParticipant.id),
        timestamp: millisecondsTimestamp
    }));

    if (typeof APP !== 'undefined') {
        // Logic for web only:

        APP.API.notifyReceivedChatMessage({
            body: message,
            id,
            nick: displayName,
            privateMessage,
            ts: timestamp
        });

        dispatch(showToolbox(4000));
    }
}

// Prospatium -----
function translateAsync(str, from, to, confidence, dispatch, state, jsonString)
{
    fetch('https://translation.googleapis.com/language/translate/v2?key=AIzaSyDvbzJ1i02EMIoGTWbIRYqOZLH8dQtyCMg', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'q': str,
            'source': from,
            'target': to,
            'format': 'text'
        })
    }).then(res => res.json())
    .then(res => {
        let data = JSON.parse(jsonString);
        let message = res.data.translations[0].translatedText;
        speak(state, to, message);
        data.message = "[T] : " + message + ' (' + confidence + ')';
        translateCallback(dispatch, data);
    })
    .catch(error => {
        logger.error(error);
        // data.message = "[Translate Fail] : " + str;
        // translateCallback(dispatch, data);
    });
}

function translateCallback(dispatch, jsonInfo) {
    const displayName = jsonInfo.displayName;
    const hasRead = jsonInfo.hasRead;
    const id = jsonInfo.id;
    const messageType = jsonInfo.messageType;
    const otherType = jsonInfo.otherType;
    const message = jsonInfo.message;
    const privateMessage = jsonInfo.privateMessage;
    const recipient = jsonInfo.recipient;
    const timestamp = jsonInfo.timestamp;

    dispatch(addMessage({
        displayName,
        hasRead,
        id,
        messageType: messageType,
        otherType: otherType,
        message,
        privateMessage,
        recipient: recipient,
        timestamp: timestamp
    }));

    if (typeof APP !== 'undefined') {
        // Logic for web only:

        APP.API.notifyReceivedChatMessage({
            body: message,
            id,
            nick: displayName,
            privateMessage,
            ts: timestamp
        });

        dispatch(showToolbox(4000));
    }
    // Prospatium -----
}

function speak(state, lang, message)
{
    const { tts_enabled } = state['features/base/conference'];
    if (tts_enabled)
    {
        let synth = window.speechSynthesis;
        let voices = synth.getVoices();
        if (voices.length === 0) {
            synth.onvoiceschanged = function() {
                logger.log("synth.onvoiceschanged retry speak");
                speak(state, lang, message);
            };
            return;
        }

        let voice = null;
        for(let i = 0; i < voices.length ; i++) {
            if (voices[i].lang.split('-', 2)[0] === lang) {
                voice = voices[i];
                break;
            }
        }

        // Firefox won't repeat an utterance that has been
        // spoken, so we need to create a new instance each time
        const utterance = new window.SpeechSynthesisUtterance();
        utterance.text = message;
        utterance.voice = voice;
        utterance.onend = function (_event) {
            logger.log('SpeechSynthesisUtterance.onend');
        }
        utterance.onerror = function (_event) {
            logger.error('SpeechSynthesisUtterance.onerror');
        }
        utterance.rate = 1;
        utterance.pitch = 1;
        window.speechSynthesis.speak(utterance);
    }
}


/**
 * Persists the sent private messages as if they were received over the muc.
 *
 * This is required as we rely on the fact that we receive all messages from the muc that we send
 * (as they are sent to everybody), but we don't receive the private messages we send to another participant.
 * But those messages should be in the store as well, otherwise they don't appear in the chat window.
 *
 * @param {Store} store - The Redux store.
 * @param {string} recipientID - The ID of the recipient the private message was sent to.
 * @param {string} message - The sent message.
 * @returns {void}
 */
function _persistSentPrivateMessage({ dispatch, getState }, recipientID, message) {
    const localParticipant = getLocalParticipant(getState);
    const displayName = getParticipantDisplayName(getState, localParticipant.id);

    dispatch(addMessage({
        displayName,
        hasRead: true,
        id: localParticipant.id,
        messageType: MESSAGE_TYPE_LOCAL,
        message,
        privateMessage: true,
        recipient: getParticipantDisplayName(getState, recipientID),
        timestamp: Date.now()
    }));
}

/**
 * Returns the ID of the participant who we may have wanted to send the message
 * that we're about to send.
 *
 * @param {Object} state - The Redux state.
 * @param {Object} action - The action being dispatched now.
 * @returns {string?}
 */
function _shouldSendPrivateMessageTo(state, action): ?string {
    if (action.ignorePrivacy) {
        // Shortcut: this is only true, if we already displayed the notice, so no need to show it again.
        return undefined;
    }

    const { messages, privateMessageRecipient } = state['features/chat'];

    if (privateMessageRecipient) {
        // We're already sending a private message, no need to warn about privacy.
        return undefined;
    }

    if (!messages.length) {
        // No messages yet, no need to warn for privacy.
        return undefined;
    }

    // Platforms sort messages differently
    const lastMessage = navigator.product === 'ReactNative'
        ? messages[0] : messages[messages.length - 1];

    if (lastMessage.messageType === MESSAGE_TYPE_LOCAL) {
        // The sender is probably aware of any private messages as already sent
        // a message since then. Doesn't make sense to display the notice now.
        return undefined;
    }

    if (lastMessage.privateMessage) {
        // We show the notice if the last received message was private.
        return lastMessage.id;
    }

    // But messages may come rapidly, we want to protect our users from mis-sending a message
    // even when there was a reasonable recently received private message.
    const now = Date.now();
    const recentPrivateMessages = messages.filter(
        message =>
            message.messageType !== MESSAGE_TYPE_LOCAL
            && message.privateMessage
            && message.timestamp + PRIVACY_NOTICE_TIMEOUT > now);
    const recentPrivateMessage = navigator.product === 'ReactNative'
        ? recentPrivateMessages[0] : recentPrivateMessages[recentPrivateMessages.length - 1];

    if (recentPrivateMessage) {
        return recentPrivateMessage.id;
    }

    return undefined;
}
