// @flow

import InlineDialog from "@atlaskit/inline-dialog";
import React, { Component } from "react";
import { Checkbox } from "@atlaskit/checkbox";

import { parseURLParams } from "../../base/util";
import { getRoomName } from "../../base/conference";
import { getToolbarButtons } from "../../base/config";
import { translate } from "../../base/i18n";
import {
    Icon,
    IconArrowDown,
    IconArrowUp,
    IconPhone,
    IconVolumeOff,
    IconInfo
} from "../../base/icons";
import { isVideoMutedByUser } from "../../base/media";
import {
    ActionButton,
    InputField,
    PreMeetingScreen,
    ToggleButton,
} from "../../base/premeeting";
import { connect } from "../../base/redux";
import { getDisplayName, updateSettings } from "../../base/settings";
import { getLocalJitsiVideoTrack, getLocalTracks } from "../../base/tracks";
import { isButtonEnabled } from "../../toolbox/functions.web";
import { getDisableDisplaynameChange } from "../../settings";
import {
    joinConference as joinConferenceAction,
    joinConferenceWithoutAudio as joinConferenceWithoutAudioAction,
    setSkipPrejoin as setSkipPrejoinAction,
    setJoinByPhoneDialogVisiblity as setJoinByPhoneDialogVisiblityAction,
} from "../actions";
import {
    isDeviceStatusVisible,
    isDisplayNameRequired,
    isJoinByPhoneButtonVisible,
    isJoinByPhoneDialogVisible,
    isPrejoinSkipped,
    checkAgreementConfigValue,
    checkAgreementPlatformConfigValue
} from "../functions";

import JoinByPhoneDialog from "./dialogs/JoinByPhoneDialog";
import DeviceStatus from "./preview/DeviceStatus";
import logger from "../logger";
import LOCATION from "../../../../location.json";
import { isMobileBrowser } from '../../base/environment/utils';
declare var interfaceConfig: Object;

type Props = {
    //Prospatium ---
    isHost: boolean,
    lobbyMode: number,
    schedule_id: string,
    property_code: string,
    userJoined: boolean,
    connectionJid: string,
    //Prospatium ---

    /**
     * Flag signaling if the 'skip prejoin' button is toggled or not.
     */
    buttonIsToggled: boolean,

    /**
     * Flag signaling if the device status is visible or not.
     */
    deviceStatusVisible: boolean,

    /**
     * If join by phone button should be visible.
     */
    hasJoinByPhoneButton: boolean,

    /**
     * Joins the current meeting.
     */
    joinConference: Function,

    /**
     * Joins the current meeting without audio.
     */
    joinConferenceWithoutAudio: Function,

    /**
     * The name of the user that is about to join.
     */
    name: string,

    /**
     * Updates settings.
     */
    updateSettings: Function,

    /**
     * The name of the meeting that is about to be joined.
     */
    roomName: string,

    /**
     * Sets visibility of the prejoin page for the next sessions.
     */
    setSkipPrejoin: Function,

    /**
     * Sets visibility of the 'JoinByPhoneDialog'.
     */
    setJoinByPhoneDialogVisiblity: Function,

    /**
     * Indicates whether the avatar should be shown when video is off
     */
    showAvatar: boolean,

    /**
     * Flag signaling the visibility of camera preview.
     */
    showCameraPreview: boolean,

    /**
     * If should show an error when joining without a name.
     */
    showErrorOnJoin: boolean,

    /**
     * Flag signaling the visibility of join label, input and buttons
     */
    showJoinActions: boolean,

    /**
     * Flag signaling the visibility of the conference URL section.
     */
    showConferenceInfo: boolean,

    /**
     * If 'JoinByPhoneDialog' is visible or not.
     */
    showDialog: boolean,

    /**
     * Flag signaling the visibility of the skip prejoin toggle
     */
    showSkipPrejoin: boolean,

    /**
     * Used for translation.
     */
    t: Function,

    /**
     * The JitsiLocalTrack to display.
     */
    videoTrack: ?Object,
    disableChangeDisplayName: boolean,

    /**
     * Array with the buttons which this Toolbox should display.
     */
    visibleButtons: Array<string>,
};

type State = {
    //Prospatium ---
    disableJoinButton: boolean,
    ageChecked: boolean,
    privacyPolicyChecked: boolean,
    virtualClinicChecked: boolean,
    //Prospatium ---
    /**
     * Flag controlling the visibility of the error label.
     */
    showError: boolean,

    /**
     * Flag controlling the visibility of the 'join by phone' buttons.
     */
    showJoinByPhoneButtons: boolean,
};

/**
 * This component is displayed before joining a meeting.
 */
class Prejoin extends Component<Props, State> {
    /**
     * Default values for {@code Prejoin} component's properties.
     *
     * @static
     */
    static defaultProps = {
        //Prospatium ---
        userJoined: false,
        showSkipPrejoin: false,
        //Prospatium ---
        showConferenceInfo: true,
        showJoinActions: true,
        showSkipPrejoin: true,
    };

    /**
     * Initializes a new {@code Prejoin} instance.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);

        this.state = {
            //Prospatium ---
            disableJoinButton: this.props.lobbyMode === 2,
            ageChecked: !this.props.isShowTermsAgreement,
            privacyPolicyChecked: !this.props.isShowTermsAgreement,
            virtualClinicChecked: !this.props.isShowTermsAgreementCustom,
            //Prospatium ---
            showError: false,
            showJoinByPhoneButtons: false,
        };

        this._closeDialog = this._closeDialog.bind(this);
        this._showDialog = this._showDialog.bind(this);
        this._onJoinButtonClick = this._onJoinButtonClick.bind(this);
        this._onToggleButtonClick = this._onToggleButtonClick.bind(this);
        this._onDropdownClose = this._onDropdownClose.bind(this);
        this._onOptionsClick = this._onOptionsClick.bind(this);
        this._setName = this._setName.bind(this);
        this._onJoinConferenceWithoutAudioKeyPress =
            this._onJoinConferenceWithoutAudioKeyPress.bind(this);
        this._showDialogKeyPress = this._showDialogKeyPress.bind(this);
        this._onJoinKeyPress = this._onJoinKeyPress.bind(this);
        //Prospatium ---
        this._checkButtonEnable = this._checkButtonEnable.bind(this);
        this._checkButtonEnable();
        this._sendWaitUserInformation =
            this._sendWaitUserInformation.bind(this);
        this._joinConference = this._joinConference.bind(this);
        //Prospatium ---
    }
    _onJoinButtonClick: () => void;

    /**
     * Handler for the join button.
     *
     * @param {Object} e - The synthetic event.
     * @returns {void}
     */
    _onJoinButtonClick() {
        //Prospatium ---
        console.log("[PREJOIN] : JoinButtonClicked ");
        this.props.userJoined = true;
        //Prospatium ---
        if (this.props.showErrorOnJoin) {
            this.setState({
                showError: true,
            });

            return;
        }

        this.setState({ showError: false });
        this.props.joinConference();
    }

    _onJoinKeyPress: (Object) => void;

    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onJoinKeyPress(e) {
        if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            this._onJoinButtonClick();
        }
    }

    _onToggleButtonClick: () => void;

    /**
     * Handler for the toggle button.
     *
     * @param {Object} e - The synthetic event.
     * @returns {void}
     */
    _onToggleButtonClick() {
        this.props.setSkipPrejoin(!this.props.buttonIsToggled);
    }

    _onDropdownClose: () => void;

    /**
     * Closes the dropdown.
     *
     * @returns {void}
     */
    _onDropdownClose() {
        this.setState({
            showJoinByPhoneButtons: false,
        });
    }

    _onOptionsClick: () => void;

    /**
     * Displays the join by phone buttons dropdown.
     *
     * @param {Object} e - The synthetic event.
     * @returns {void}
     */
    _onOptionsClick(e) {
        e.stopPropagation();

        this.setState({
            showJoinByPhoneButtons: !this.state.showJoinByPhoneButtons,
        });
    }

    _setName: () => void;

    /**
     * Sets the guest participant name.
     *
     * @param {string} displayName - Participant name.
     * @returns {void}
     */
    _setName(displayName) {
        this.props.updateSettings({
            displayName,
        });
    }

    _closeDialog: () => void;

    /**
     * Closes the join by phone dialog.
     *
     * @returns {undefined}
     */
    _closeDialog() {
        this.props.setJoinByPhoneDialogVisiblity(false);
    }

    _showDialog: () => void;

    /**
     * Displays the dialog for joining a meeting by phone.
     *
     * @returns {undefined}
     */
    _showDialog() {
        this.props.setJoinByPhoneDialogVisiblity(true);
        this._onDropdownClose();
    }

    _showDialogKeyPress: (Object) => void;

    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _showDialogKeyPress(e) {
        if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            this._showDialog();
        }
    }

    _onJoinConferenceWithoutAudioKeyPress: (Object) => void;

    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onJoinConferenceWithoutAudioKeyPress(e) {
        if (
            this.props.joinConferenceWithoutAudio &&
            (e.key === " " || e.key === "Enter")
        ) {
            e.preventDefault();
            this.props.joinConferenceWithoutAudio();
        }
    }
    //Prospatium ---
    _sendWaitUserInformation: () => void;

    _sendWaitUserInformation() {
        logger.info(
            "[PREJOIN] need call API to send user infomation ",
            this.props.connectionJid
        );
        logger.info(
            "[PREJOIN] ",
            this.props.userDescription,
            "  ",
            this.props.userName,
            "  ",
            this.props.name
        );
        // session-connect �� ������ wait ���·� attendee insert
        try {
            return new Promise(async ( resolve, _reject ) => {
                const url = "/api/v1/statusManager/update-prejoin-participant";
                let audioMuted = true;
                let videoMuted = true;
                const localTracks = getLocalTracks(
                    APP.store.getState()["features/base/tracks"]
                );
                for (let i = 0; i < localTracks.length; i++) {
                    if (localTracks[i].mediaType === "audio") {
                        audioMuted = localTracks[i].muted ?? true;
                    } else if (localTracks[i].mediaType === "video") {
                        videoMuted = localTracks[i].muted ?? true;
                    }
                }

                var body = {
                    attendee_jid: this.props.connectionJid,
                    call_id: "SESSION",
                    is_host: this.props.isHost,
                    attendee_name: this.props.name,
                    attendee_audio_muted: audioMuted,
                    attendee_video_muted: videoMuted,
                    schedule_id: this.props.schedule_id,
                    property_code: this.props.property_code,
                    attendee_user_agent: this.props.userAgent,
                    attendee_param: this.props.userParam,
                    attendee_description: this.props.userDescription,
                };

                await fetch(url, {
                    method: "PUT",
                    headers: {
                        "Content-type": "application/json",
                    },
                    body: JSON.stringify(body),
                });
                resolve();
            });
        } catch (error) {
            logger.error(
                "[PREJION:API] getting schedule information failed",
                error
            );
        }
    }
    _joinConference: () => void;

    _joinConference() {
        if (!this.state.disableJoinButton) {
            this.props.joinConference();
        }
    }

    _checkButtonEnable: () => boolean;

    _checkButtonEnable() {
        if (this.props.userJoined) {
            return;
        }

        if (this.props.connectionJid === "") {
            logger.info("[PREJOIN] wait for geting connection jid ");
            setTimeout(this._checkButtonEnable, 3000);
            return;
        } else {
            this._sendWaitUserInformation();
        }

        if (this.props.lobbyMode !== 2) {
            return;
        }

        try {
            return new Promise(async (resolve, _reject) => {
                const url = "/api/v1/app/getHostUserCountInActiveCall";
                var body = { schedule_id: this.props.schedule_id };

                const res = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json",
                    },
                    body: JSON.stringify(body),
                });

                const json = await res.json();

                if (res.ok) {
                    const { host_user_count } = json.data[0];
                    logger.info(
                        "[PREJOIN] host_user_count : ",
                        host_user_count
                    );
                    if (this.props.isHost) {
                        this.setState({ disableJoinButton: false });
                        // moderator가 0명이면 입장 처리
                        if (host_user_count === 0 &&
                            ((this.props.isShowTermsAgreement && (this.state.ageChecked &&
                            this.state.privacyPolicyChecked))
                            ||
                            (this.props.isShowTermsAgreementCustom && this.state.virtualClinicChecked))
                        ) {
                            setTimeout(this._onJoinButtonClick, 500);
                            resolve();
                            return;
                        }
                        if (host_user_count === 0 &&
                            (!this.props.isShowTermsAgreement && !this.props.isShowTermsAgreementCustom))
                        {
                            setTimeout(this._onJoinButtonClick, 500);
                            resolve();
                            return;
                        }
                    }

                    const moderatorJoined = !(host_user_count > 0);
                    this.setState({ disableJoinButton: moderatorJoined });
                    setTimeout(this._checkButtonEnable, 5000);
                    resolve();
                } else {
                    logger.info("[PREJION:API] error", json);
                    this.setState({ disableJoinButton: false });
                }
            });
        } catch (error) {
            logger.error(
                "[PREJION:API] getting schedule information failed",
                error
            );
        }
    }
    //Prospatium ---

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */

    render() {
        const {
            hasJoinByPhoneButton,
            joinConferenceWithoutAudio,
            name,
            showAvatar,
            showCameraPreview,
            showDialog,
            showConferenceInfo,
            showJoinActions,
            t,
            disableChangeDisplayName,
            videoTrack,
            visibleButtons,
            lobbyMode,
        } = this.props;

        const {
            _closeDialog,
            _onDropdownClose,
            _onJoinButtonClick,
            _onJoinKeyPress,
            _showDialogKeyPress,
            _onJoinConferenceWithoutAudioKeyPress,
            _onOptionsClick,
            _setName,
            _showDialog,
            _joinConference,
        } = this;
        const direction = localStorage.language === 'ar' ? 'rtl' : null;
        let className = direction =='rtl'? 'rtl' : null;
        //Prospatium ---
        // const { showJoinByPhoneButtons, showError } = this.state;
        const { showJoinByPhoneButtons, showError, disableJoinButton } =
            this.state;
        var titleMsg;
        var _tabIndex = 0;
        if ( this.props.isShowTermsAgreement || this.props.isShowTermsAgreementCustom) {
            titleMsg = "prejoin.accept";
        } else {
            titleMsg = "prejoin.joinMeeting";
        }
        if (this.props.isShowTermsAgreement && !(this.state.privacyPolicyChecked && this.state.ageChecked)) {
            titleMsg = "prejoin.accept";
            _tabIndex = -1;
        } else if (this.props.isShowTermsAgreementCustom && !this.state.virtualClinicChecked) {
            titleMsg = "prejoin.accept";
            _tabIndex = -1;
        } else if (disableJoinButton) {
            titleMsg = "prejoin.waitHostUser";
            _tabIndex = -1;
        }
        //Prospatium ---

        const _location = LOCATION.location;
        function terms() {
            if (_location === "kor") {
                return (
                    <div>
                        <a
                            href="admin/terms-of-use-meet"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t("prejoin.terms")}
                        </a>{" "}
                        /
                        <a
                            href="admin/privacy-policy-meet"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t("prejoin.personalInfo")}
                        </a>{" "}
                        /
                        <a
                            href="admin/personal-info-policy-meet"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t("prejoin.personalInfoAgreement")}
                        </a>
                    </div>
                );
            }
            return (
                <div>
                    <a
                        href="admin/terms-of-use-meet"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("prejoin.terms")}
                    </a>{" "}
                    /
                    <a
                        href="admin/privacy-policy-meet"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("prejoin.personalInfo")}
                    </a>
                </div>
            );
        }

        return (
            <PreMeetingScreen
                footer={this._renderFooter()}
                name={name}
                showAvatar={showAvatar}
                showConferenceInfo={showConferenceInfo}
                skipPrejoinButton={this._renderSkipPrejoinButton()}
                //Prospatium ---
                // title = { t('prejoin.joinMeeting') }
                title={t(titleMsg)}
                //Prospatium ---
                videoMuted={!showCameraPreview}
                videoTrack={videoTrack}
                visibleButtons={visibleButtons}
            >
                {this.props.isShowTermsAgreement && (
                    <div className="agreement" color="white">
                        <Checkbox
                            isChecked={this.state.ageChecked}
                            value="ageCheck"
                            label={t("prejoin.ageAgreement")}
                            onChange={() => {
                                this.setState({
                                    ageChecked: !this.state.ageChecked,
                                });
                            }}
                        />
                        {_location === "kor" && (
                            <Checkbox
                                isChecked={this.state.privacyPolicyChecked}
                                value="terms"
                                label={t("prejoin.termsAgreement_kor")}
                                onChange={() => {
                                    this.setState({
                                        privacyPolicyChecked:
                                            !this.state.privacyPolicyChecked,
                                    });
                                    console.log(
                                        "this.privacyPolicyChecked : ",
                                        this.state.privacyPolicyChecked
                                    );
                                }}
                            />
                        )}

                        {_location !== "kor" && (
                            <Checkbox
                                isChecked={this.state.privacyPolicyChecked}
                                value="terms"
                                label={t("prejoin.termsAgreement_global")}
                                onChange={() => {
                                    this.setState({
                                        privacyPolicyChecked:
                                            !this.state.privacyPolicyChecked,
                                    });
                                    console.log(
                                        "this.privacyPolicyChecked : ",
                                        this.state.privacyPolicyChecked
                                    );
                                }}
                            />
                        )}

                        {terms()}
                    </div>
                )}
                {this.props.isShowTermsAgreementCustom && (
                    <div className="agreementCustom" color="white">
                        <Icon
                            className="virtual_clinic-icon"
                            size = {30}
                            src = { IconInfo } />
                        <div className="consent_virtual_clinic" dir = { direction }>
                            <p style={{ color : 'red', textAlign : 'center'}}><b>{t("prejoin.virtualClinicSub1")}</b></p>
                            <p style={{ color : 'red', textAlign : 'center'}}><b>{t("prejoin.virtualClinicSub2")}</b></p>
                            <ul>
                                <li><p style={{ fontSize : '0.9em'}}>{t("prejoin.virtualClinicDesc1")}</p></li>
                                <li><p style={{ fontSize : '0.9em'}}>{t("prejoin.virtualClinicDesc2")}</p></li>
                                <li><p style={{ fontSize : '0.9em'}}>{t("prejoin.virtualClinicDesc3")}</p></li>
                                <li><p style={{ fontSize : '0.9em'}}>{t("prejoin.virtualClinicDesc4")}</p></li>
                            </ul>
                        </div>
                        <div className={`checkbox_virtual_clinic ${className}`}>
                        <Checkbox
                            isChecked={this.state.virtualClinicChecked}
                            value="virtualClinicCheck"
                            label={t("prejoin.virtualClinicAgreement")}
                            onChange={() => {
                                this.setState({
                                    virtualClinicChecked: !this.state.virtualClinicChecked,
                                });
                            }}
                        />
                        </div>
                    </div>
                )}
                {showJoinActions && (
                    <div className="prejoin-input-area-container">
                        <div className="prejoin-input-area">
                            {lobbyMode !== 0 && (
                                <div>
                                    <label
                                        className="prejoin-input-area-label"
                                        htmlFor={"Prejoin-input-field-id"}
                                    >
                                        {t("dialog.enterDisplayNameToJoin")}
                                    </label>
                                    <InputField
                                        autoComplete={"name"}
                                        autoFocus={true}
                                        className={showError ? "error" : ""}
                                        hasError={showError}
                                        id={"Prejoin-input-field-id"}
                                        onChange={_setName}
                                        onSubmit={_joinConference}
                                        placeHolder={t(
                                            "dialog.enterDisplayName"
                                        )}
                                        readOnly={disableChangeDisplayName}
                                        maxLength={
                                            "50" /* limit input displayName length. CDQAPP-28737*/
                                        }
                                        value={name}
                                    />
                                </div>
                            )}
                            {showError && (
                                <div
                                    className="prejoin-error"
                                    data-testid="prejoin.errorMessage"
                                >
                                    {t("prejoin.errorMissingName")}
                                </div>
                            )}

                            <div
                                className="prejoin-preview-dropdown-container"
                                style={{ position: "relative", bottom: "10px" }}
                            >
                                {/* Delete InlineDialog prejoin.joinWithoutAudio" [PRSPTM-1377]*/}
                                <InlineDialog
                                    content={
                                        hasJoinByPhoneButton && (
                                            <div className="prejoin-preview-dropdown-btns">
                                                <div
                                                    className="prejoin-preview-dropdown-btn"
                                                    onClick={_showDialog}
                                                    onKeyPress={
                                                        _showDialogKeyPress
                                                    }
                                                    role="button"
                                                    tabIndex={_tabIndex}
                                                >
                                                    <Icon
                                                        className="prejoin-preview-dropdown-icon"
                                                        data-testid="prejoin.joinByPhone"
                                                        size={24}
                                                        src={IconPhone}
                                                    />
                                                    {t(
                                                        "prejoin.joinAudioByPhone"
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                    isOpen={showJoinByPhoneButtons}
                                    onClose={_onDropdownClose}
                                >
                                    <ActionButton
                                        OptionsIcon={
                                            showJoinByPhoneButtons
                                                ? IconArrowUp
                                                : IconArrowDown
                                        }
                                        ariaLabel={t("prejoin.joinMeeting")}
                                        ariaPressed={showJoinByPhoneButtons}
                                        hasOptions={showJoinByPhoneButtons || hasJoinByPhoneButton}
                                        onClick={_onJoinButtonClick}
                                        onKeyPress={_onJoinKeyPress}
                                        onOptionsClick={_onOptionsClick}
                                        role="button"
                                        tabIndex={_tabIndex}
                                        testId="prejoin.joinMeeting"
                                        //Prospatium ---
                                        name="ProSpatiumPrejoinJoinMeeting"
                                        disabled={
                                            disableJoinButton ||
                                            (this.props.isShowTermsAgreement && !(this.state.privacyPolicyChecked && this.state.ageChecked)) ||
                                            (this.props.isShowTermsAgreementCustom && !this.state.virtualClinicChecked)
                                        }
                                        //Prospatium ---
                                        type="primary"
                                    >
                                        {t(titleMsg)}
                                    </ActionButton>
                                </InlineDialog>
                            </div>
                        </div>
                    </div>
                )}
                {showDialog && (
                    <JoinByPhoneDialog
                        joinConferenceWithoutAudio={joinConferenceWithoutAudio}
                        onClose={_closeDialog}
                    />
                )}
            </PreMeetingScreen>
        );
    }

    /**
     * Renders the screen footer if any.
     *
     * @returns {React$Element}
     */
    _renderFooter() {
        return this.props.deviceStatusVisible && <DeviceStatus />;
    }

    /**
     * Renders the 'skip prejoin' button.
     *
     * @returns {React$Element}
     */
    _renderSkipPrejoinButton() {
        const { showSkipPrejoin } = this.props;

        if (!showSkipPrejoin) {
            return null;
        }
        // Prospatium
        return <div></div>;
        // Prospatium
    }
}

/**
 * Maps (parts of) the redux state to the React {@code Component} props.
 *
 * @param {Object} state - The redux state.
 * @param {Object} ownProps - The props passed to the component.
 * @returns {Object}
 */
function mapStateToProps(state, ownProps): Object {
    const name = getDisplayName(state);
    const showErrorOnJoin = isDisplayNameRequired(state) && !name;
    const { showJoinActions } = ownProps;
    const isInviteButtonEnabled = isButtonEnabled("invite", state);
    //Prospatium ---
    const { jwt } = state["features/base/jwt"];
    const scheduleConfing = state["features/base/config"].prospatium;
    const scheduleinterfaceConfig = scheduleConfing.schedule_interface_config;
    const lobbyMode = scheduleinterfaceConfig.PS_LOBBY;
    const schedule_id = scheduleConfing.schedule_id;
    const property_code = scheduleConfing.property_code;

    const propertyInterfaceConfig = scheduleConfing.property_interface_config;

    var showTermsAgreement = checkAgreementConfigValue(propertyInterfaceConfig.PS_SHOW_TERMS_AGREEMENT);
    var showTermsAgreementPlatform = checkAgreementPlatformConfigValue(propertyInterfaceConfig.PS_SHOW_TERMS_AGREEMENT_PLATFORM);
    let isShowTermsAgreement = false;
    let isShowTermsAgreementCustom = false;

    if (showTermsAgreement === 'show') {
        if (showTermsAgreementPlatform === 'all') {
            isShowTermsAgreement = true;
        } else if (showTermsAgreementPlatform === 'mobile') {
            if (isMobileBrowser()) {
                isShowTermsAgreement = true;
            }
        } else if (showTermsAgreementPlatform === 'web') {
            if (!isMobileBrowser()) {
                isShowTermsAgreement = true;
            }
        } else {
            console.log('[showTermsAgreement]check platform.', showTermsAgreementPlatform);
        }
    } else {
        console.log('[showTermsAgreement] check Agreement',showTermsAgreement);
    }

    if (showTermsAgreement === 'custom') {
        if (showTermsAgreementPlatform === 'all') {
            isShowTermsAgreementCustom = true;
        } else if (showTermsAgreementPlatform === 'mobile') {
            if (isMobileBrowser()) {
                isShowTermsAgreementCustom = true;
            }
        } else if (showTermsAgreementPlatform === 'web') {
            if (!isMobileBrowser()) {
                isShowTermsAgreementCustom = true;
            }
        } else {
            console.log('[showTermsAgreement]check platform.',showTermsAgreementPlatform)
        }
    } else {
        console.log('[showTermsAgreement] check Agreement',showTermsAgreement);
    }
    const connection = state["features/base/connection"].connection;

    const urlParams = parseURLParams(
        state["features/base/connection"].locationURL
    );
    const userName = urlParams["userInfo.displayName"];
    var userDescription = urlParams["userInfo.description"];
    const userAgent = navigator.userAgent;
    const userParam = "";
    const isShowAgreeement = urlParams["agreement"] === 'show';

    isShowTermsAgreement = isShowTermsAgreement && isShowAgreeement;
    isShowTermsAgreementCustom = isShowTermsAgreementCustom && isShowAgreeement;

    var connectionJid = "";
    if (connection) {
        connectionJid = connection.getJid();
    }
    //Prospatium ---

    // Hide conference info when interfaceConfig is available and the invite button is disabled.
    // In all other cases we want to preserve the behaviour and control the the conference info
    // visibility through showJoinActions.
    const showConferenceInfo =
        typeof isInviteButtonEnabled === "undefined" ||
        isInviteButtonEnabled === true
            ? showJoinActions
            : false;

    return {
        //Prospatium ---
        isHost: !!jwt,
        lobbyMode: lobbyMode,
        schedule_id: schedule_id,
        property_code: property_code,
        isShowTermsAgreement: isShowTermsAgreement,
        isShowTermsAgreementCustom: isShowTermsAgreementCustom,
        connectionJid: connectionJid,
        userName: userName,
        userDescription: userDescription,
        userAgent: userAgent,
        userParam: userParam,
        disableChangeDisplayName: getDisableDisplaynameChange(state),
        //Prospatium ---
        buttonIsToggled: isPrejoinSkipped(state),
        name,
        deviceStatusVisible: isDeviceStatusVisible(state),
        roomName: getRoomName(state),
        showDialog: isJoinByPhoneDialogVisible(state),
        showErrorOnJoin,
        hasJoinByPhoneButton: isJoinByPhoneButtonVisible(state),
        showCameraPreview: !isVideoMutedByUser(state),
        showConferenceInfo,
        videoTrack: getLocalJitsiVideoTrack(state),
        visibleButtons: getToolbarButtons(state),
    };
}

const mapDispatchToProps = {
    joinConferenceWithoutAudio: joinConferenceWithoutAudioAction,
    joinConference: joinConferenceAction,
    setJoinByPhoneDialogVisiblity: setJoinByPhoneDialogVisiblityAction,
    setSkipPrejoin: setSkipPrejoinAction,
    updateSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(translate(Prejoin));
